const ChanuntornLogo = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 800 800"
    style={{
      enableBackground: "new 0 0 800 800",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{opacity:0.8;}\n\t.st1{fill:#781216;}\n\t.st2{opacity:0.3;}\n\t.st3{opacity:0.6;}\n"
      }
    </style>
    <g>
      <g className="st0">
        <polygon
          className="st1"
          points="554.8,779.7 276.2,653.2 513.5,663.7  "
        />
      </g>
      <g>
        <polygon
          className="st1"
          points="689,716.4 419.2,62.5 296.8,20.3 565.1,779.7  "
        />
      </g>
      <g>
        <polygon
          className="st1"
          points="730.3,526.6 606.4,220.7 523.8,167.9 689,653.2  "
        />
      </g>
      <g className="st2">
        <polygon className="st1" points="69.8,705.4 224,642.1 496.5,779.5  " />
      </g>
      <g className="st3">
        <polygon
          className="st1"
          points="286.5,30.8 69.7,695.3 224.5,632.1 358.7,231.2  "
        />
      </g>
    </g>
  </svg>
);
export default ChanuntornLogo;
