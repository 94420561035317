import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from '../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteDialog from '../components/Dialog/DeleteDialog';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as XLSX from 'xlsx';


const SalesTargets = () => {

    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [showFilter, setShowFilter] = useState(false);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openContenttDialog, setOpenContentDialog] = useState(false);
    const [singleData, setSingleData] = useState({
        id: null,
        questionnaire_id: null,
        period_start: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        period_end: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
        sales_target: 0,
        transfer_target: 0,
        cancellation_target: 0
    });
    const clearSingleData = () => {
        setSingleData({
            id: null,
            questionnaire_id: null,
            period_start: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
            period_end: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
            sales_target: 0,
            transfer_target: 0,
            cancellation_target: 0
        });
    }

    const fetchcontents = useCallback((value = 1) => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/get/${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.sales_targets);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/get-questionnaires`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setQuestionnaires(response.data);
            }
        }).catch(function (error) {
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, []);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleDelete = () => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpen(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
                fetchcontents();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const validateData = () => {
        for (const [key, value] of Object.entries(singleData)) {
            if (value === '' || value === 0) {
                console.log(key);
                return false;
            }
        }
        return true;
    }

    const handleAddneworUpdate = (addorupdate) => {

        if (!validateData()) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกข้อมูลให้ครบถ้วน'
            });
            return;
        }

        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/addnew`;
        if (addorupdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/update/${singleData.id}`;
        }
        setIsLoading(true)
        axios({
            method: addorupdate === 'add' ? 'post' : 'put',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: singleData.id,
                questionnaire_id: singleData.questionnaire_id,
                period_start: dayjs(singleData.period_start).format('YYYY-MM-DD'),
                period_end: dayjs(singleData.period_end).format('YYYY-MM-DD'),
                sales_target: singleData.sales_target,
                transfer_target: singleData.transfer_target,
                cancellation_target: singleData.cancellation_target,
            }
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpenContentDialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: addorupdate === 'add' ? 'เพิ่มข้อมูลสำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'
                });
                fetchcontents();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const exportToExcel = () => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/sales-targets/export`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            params: {
                start: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                const ws = XLSX.utils.json_to_sheet(response.data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "เป้าหมาย");
                XLSX.writeFile(wb, `เป้าหมาย_${dayjs(filterDate.startDate).format('DD-MM-YYYY')}_${dayjs(filterDate.endDate).format('DD-MM-YYYY')}.xlsx`);
                setShowFilter(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'Export to Excel สำเร็จ'
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <Layout mainClass='bg-gray-100'>

            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-end w-full'>
                    <div className='flex gap-4 items-end'>
                        <h1 className='text-2xl font-bold hidden lg:block'>เป้าหมาย</h1>
                    </div>
                    <div className='flex gap-4 items-end'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setOpenContentDialog(true);
                                clearSingleData();
                            }}
                        >
                            + เพิ่มเป้าหมาย
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setShowFilter(!showFilter);
                            }}
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-1/6 font-bold'>วันที่</div>
                            <div className='w-1/6 font-bold'>แบบสอบถาม</div>
                            <div className='w-1/6 font-bold'>เป้าขาย</div>
                            <div className='w-1/6 font-bold'>เป้าโอนฯ</div>
                            <div className='w-1/6 font-bold'>เป้ายกเลิก</div>
                            <div className='w-1/6 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-1/6'>{content.period_start} ถึง {content.period_end}</div>
                                <div className='w-1/6'>{content.q_title}</div>
                                <div className='w-1/6'>{content.sales_target?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className='w-1/6'>{content.transfer_target?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className='w-1/6'>{content.cancellation_target?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className='w-1/6 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow>
                                        <IconButton aria-label="edit" onClick={() => {
                                            setSingleData({
                                                id: content.id,
                                                questionnaire_id: content.questionnaire_id,
                                                period_start: new Date(dayjs(content.period_start).format('YYYY-MM-DD')),
                                                period_end: new Date(dayjs(content.period_end).format('YYYY-MM-DD')),
                                                sales_target: content.sales_target,
                                                transfer_target: content.transfer_target,
                                                cancellation_target: content.cancellation_target
                                            });
                                            setOpenContentDialog(true);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" onClick={() => {
                                            setDeleteId(content.id)
                                            setOpen(true);
                                        }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <DeleteDialog
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
                dialogTitle='ยินยันการลบข้อมูล'
                dialogDescription='ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้'
                setDeleteId={setDeleteId}
                isLoading={isLoading}
            />

            <Dialog
                onClose={() => setOpenContentDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openContenttDialog}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>{singleData.expense_id === null ? 'เพิ่ม' : 'แก้ไข'}เป้าหมาย</h2>
                    <div className='flex flex-col gap-4'>

                        <div className='mb-2'>
                            <Autocomplete
                                disablePortal
                                options={questionnaires}
                                getOptionLabel={(option) => option.q_title}
                                value={questionnaires.find((q) => q.questionnaire_id === singleData.questionnaire_id) || null}
                                onChange={(event, newValue) => {
                                    setSingleData({ ...singleData, questionnaire_id: newValue ? newValue.questionnaire_id : null });
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="เลือกแบบสอบถาม" placeholder="เลือกแบบสอบถาม" />}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>วันที่</label>
                            <DateRangePicker
                                size="lg"
                                className='w-full'
                                appearance="default"
                                placeholder="เลือกช่วงเวลาของค่าใช้จ่าย"
                                value={[singleData.period_start, singleData.period_end]}
                                onChange={(value) => {
                                    setSingleData({ ...singleData, period_start: value[0], period_end: value[1] });
                                    console.log(value);
                                }}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>เป้าขาย</label>
                            <input
                                type='number'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.sales_target}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, sales_target: e.target.value });
                                }}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>เป้าโอนฯ</label>
                            <input
                                type='number'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.transfer_target}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, transfer_target: e.target.value });
                                }}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'> เป้ายกเลิก</label>
                            <input
                                type='number'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.cancellation_target}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, cancellation_target: e.target.value });
                                }}
                            />
                        </div>

                    </div>


                    <div className='flex justify-end items-center gap-2 mt-4'>
                        <Button
                            onClick={() => {
                                setOpenContentDialog(false);
                                clearSingleData();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            autoFocus
                            onClick={() => {
                                handleAddneworUpdate(singleData.id === null ? 'add' : 'update');
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                onClose={() => setShowFilter(false)}
                aria-labelledby="simple-dialog-title"
                open={showFilter}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>Export to Excel</h2>
                    <div className='flex flex-col gap-4'>

                        <div className='mb-2'>
                            <label className='font-medium block'>วันที่</label>
                            <DateRangePicker
                                size="lg"
                                className='w-full'
                                appearance="default"
                                placeholder="เลือกช่วงเวลาของค่าใช้จ่าย"
                                value={[filterDate.startDate, filterDate.endDate]}
                                onChange={(value) => {
                                    setFilterDate({ ...filterDate, startDate: value[0], endDate: value[1] });
                                    console.log(value);
                                }}
                            />
                        </div>

                        <div className='flex justify-end items-center gap-2 mt-4'>
                            <Button
                                disabled={isLoading}
                                autoFocus
                                onClick={() => {
                                    exportToExcel();
                                }}
                            >
                                ส่งออกข้อมูล
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowFilter(false);
                                }}
                            >
                                ยกเลิก
                            </Button>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>

        </Layout>
    )
}

export default SalesTargets;