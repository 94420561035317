import { useState, useContext } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../components/predefinedBottomRanges';
//import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ExportExcel = ({ apiurl, questionnaires }) => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [alert, setAlert] = useState({ open: false, type: 'success', msg: '', });
    const [open, setOpen] = useState(false);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [questionnaryForExport, setQuestionnaryForExport] = useState(null);

    const fetchData = () => {

        if (!questionnaryForExport) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกแบบสอบถามที่ต้องการส่งออก',
            });
            return;
        }

        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/${apiurl}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
            params: {
                startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                questionnaire_id: questionnaryForExport,
            }
        }).then((response) => {

            const allData = response.data.data;
            const transformedData = transformDataForExcel(allData);
            exportToExcel(transformedData, `การขายโครงการ ${questionnaires.find(q => q.questionnaire_id === questionnaryForExport).q_title} วันที่ ${dayjs(filterDate.startDate).format('DD/MM/YYYY')} ถึง ${dayjs(filterDate.endDate).format('DD/MM/YYYY')}.xlsx`);
            setIsLoading(false);
            setOpen(false);

        }).catch((err) => {
            setIsLoading(false);
            if (err.response && err.response.data && err.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            } else {
                console.error('An unexpected error occurred:', err);
            }
        })
    }

    const transformDataForExcel = (data) => {
        return data.map(item => {
            let flattened = {};

            const flatten = (obj, prefix = '') => {
                Object.keys(obj).forEach(key => {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        flatten(obj[key], `${prefix}${key}.`);
                    } else {
                        const finalKey = `${prefix}${key}`.split('.').pop();
                        flattened[finalKey] = obj[key];
                    }
                });
            };

            flatten(item);
            return flattened;
        });
    };

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, fileName);
    }


    return (
        <>
            <Button className=' whitespace-nowrap' variant="contained" onClick={() => setOpen(true)}>Export</Button>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                maxWidth='xs'
                fullWidth
            >
                <div className='p-6 text-gray-600 max-w-md w-full mx-auto mt-4'>
                    <div className='mb-4'>
                        <label className='mb-4'>เลือกวันที่ต้องการส่งออกข้อมูล</label>
                        <div>
                            <DateRangePicker
                                //placement="bottomEnd"
                                size="lg"
                                placeholder="เลือกช่วงเวลา"
                                value={[filterDate.startDate, filterDate.endDate]}
                                onChange={(value) => {
                                    setFilterDate({
                                        startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                        endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                                    });
                                }}
                                ranges={predefinedBottomRanges}
                                block
                            />
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='block'>เลือกแบบสอบถามที่ต้องการส่งออก</label>
                        <Select
                            fullWidth
                            size='small'
                            value={questionnaryForExport || ''}
                            onChange={(e) => setQuestionnaryForExport(e.target.value)}
                        >
                            <MenuItem value='' disabled>เลือกแบบสอบถาม</MenuItem>
                            {questionnaires.map((questionnaire, index) => (
                                <MenuItem key={index} value={questionnaire.questionnaire_id}>{questionnaire.q_title}</MenuItem>
                            ))}
                        </Select>
                    </div>


                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button disabled={isLoading} className='btn btn-danger' onClick={fetchData}>ส่งออกข้อมูล</Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar
                className='z-50'
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </>
    );

}

export default ExportExcel;