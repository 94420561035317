import { useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import LinearProgress from '@mui/material/LinearProgress';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';

const Layout = ({ children, mainClass }) => {

    const { isLoading } = useContext(LoadingContext);

    return (
        <div className={`flex flex-col min-h-screen`}>
            {isLoading && <div className='fixed top-0 z-50 w-full'><LinearProgress color="primary" /></div>}
            <div className='flex flex-auto min-w-0'>
                <Sidebar />
                <div className='w-full'>
                    <Header />
                    <main className={`p-4 lg:p-8 pb-0 ${mainClass ? mainClass : mainClass}`}>{children}</main>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Layout;