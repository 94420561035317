import { useContext } from 'react';
import { NavigationContext } from '../contexts/NavigationContext';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
//import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
//import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
//import AstinLogo from '../components/AstinLogo';
//import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import ChanuntornLogo from '../components/ChanuntornLogo';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

export default function Sidebar() {

    const { requestNavigation } = useContext(NavigationContext);
    const location = useLocation();
    const liClass = 'flex space-x-4 items-center py-2.5 px-7 font-normal hover:text-blue hover:stroke-blue hover:border-l-4 ease-in-out duration-100 hover:bg-gray-smoke';
    const iconColor = '#595c60';
    const adminRole = JSON.parse(localStorage.getItem('admin_role'));
    const handleNavigation = (event, path) => {
        event.preventDefault();
        requestNavigation(path);
    };

    return (
        <aside className={'min-h-screen main-side-bar flex flex-col justify-between side-nav side-nav-transparent side-nav-expand border-r border-slate-150 lg:w-64 2xl:w-80 overflow-y-auto sticky top-0 self-start ' + localStorage.getItem('collapsed')}>
            <div className='' >
                <div className="side-nav-header border-b border-slate-150">
                    <div className="logo px-4 py-1">
                        <div className='flex justify-center'>
                            <ChanuntornLogo height={48} />
                        </div>
                    </div>
                </div>
                <div className="side-nav-content py-4 font-bold text-gray-dark" style={{ height: 'calc(100vh - 160px)' }}>
                    <ul className='flex flex-col justify-center'>
                        <li className='main-page-home'>
                            <NavLink
                                to="/"
                                className={liClass}
                                onClick={(event) => handleNavigation(event, '/')}
                            >
                                <span className='menu-svg-warp'>
                                    <DashboardOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    แดชบอร์ด
                                </span>
                            </NavLink>
                        </li>
                    </ul>

                    {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') &&
                        <>
                            <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                                <span>แบบสอบถาม</span>
                            </div>
                            <ul>
                                <li>
                                    <NavLink
                                        to="/questionnaires"
                                        className={({ isActive }) =>
                                            isActive && location.pathname === '/questionnaires' ? `${liClass} active` : liClass
                                        }
                                        onClick={(event) => handleNavigation(event, '/questionnaires')}
                                    >
                                        <span className='menu-svg-warp'>
                                            <FeaturedPlayListOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            แบบสอบถาม
                                        </span>
                                    </NavLink>
                                </li>
                                {/*<li>
                            <NavLink to="/questionnaires/addnew" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PlaylistAddOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    สร้างใหม่
                                </span>
                            </NavLink>
                            </li>
                        <li>
                            <NavLink to="/submissions" className={liClass} onClick={(event) => handleNavigation(event, '/submissions')}>
                                <span className='menu-svg-warp'>
                                    <ArticleOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายการตอบ
                                </span>
                            </NavLink>
                        </li>*/}
                                <li>
                                    <NavLink to="/questionnaires/qrcode" className={liClass} onClick={(event) => handleNavigation(event, '/questionnaires/qrcode')}>
                                        <span className='menu-svg-warp'>
                                            <QrCodeOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            สร้างคิวอาร์โค้ด
                                        </span>
                                    </NavLink>
                                </li>

                            </ul>
                        </>
                    }

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>การขาย</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/opportunities" className={liClass} onClick={(event) => handleNavigation(event, '/opportunities')}>
                                <span className='menu-svg-warp'>
                                    <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    การขาย
                                </span>
                            </NavLink>
                        </li>
                        {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') &&
                            <>
                                <li>
                                    <NavLink to="/customers" className={liClass} onClick={(event) => handleNavigation(event, '/customers')}>
                                        <span className='menu-svg-warp'>
                                            <PeopleAltOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            ลูกค้า
                                        </span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/sales-targets" className={liClass} onClick={(event) => handleNavigation(event, '/sales-targets')}>
                                        <span className='menu-svg-warp'>
                                            <AdsClickOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            เป้าหมาย
                                        </span>
                                    </NavLink>
                                </li>
                            </>
                        }

                        <li>
                            <NavLink to="/reports/customer-visits-from-survey" className={liClass} onClick={(event) => handleNavigation(event, '/reports/customer-visits-from-survey')}>
                                <span className='menu-svg-warp'>
                                    <ShowChartOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายงาน
                                </span>
                            </NavLink>
                        </li>

                    </ul>

                    {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') &&
                        <>
                            <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                                <span>ค่าใช้จ่าย</span>
                            </div>
                            <ul>
                                <li>
                                    <NavLink to="/expenses" className={liClass} onClick={(event) => handleNavigation(event, '/expenses')}>
                                        <span className='menu-svg-warp'>
                                            <AttachMoneyOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            ค่าใช้จ่าย
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/expenses-categories" className={liClass} onClick={(event) => handleNavigation(event, '/expenses-categories')}>
                                        <span className='menu-svg-warp'>
                                            <LocalAtmOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            หมวดหมู่
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/mktbudget" className={liClass} onClick={(event) => handleNavigation(event, '/mktbudget')}>
                                        <span className='menu-svg-warp'>
                                            <StorefrontOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            งบประมาณ
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </>
                    }

                    {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') &&
                        <>
                            <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                                <span>พนักงาน</span>
                            </div>

                            <ul>
                                <li>
                                    <NavLink to="/users" className={liClass} onClick={(event) => handleNavigation(event, '/users')}>
                                        <span className='menu-svg-warp'>
                                            <PermIdentityOutlinedIcon sx={{ color: iconColor }} />
                                        </span>
                                        <span className='menu-link-warp'>
                                            พนักงาน
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </>
                    }

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>ออกจากระบบ</span>
                    </div>
                    <ul className='mb-2'>
                        <li className='main-page-home'>
                            <NavLink to="/logout" className={liClass} onClick={(event) => handleNavigation(event, '/logout')}>
                                <span className='menu-svg-warp'>
                                    <LogoutOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ออกจากระบบ
                                </span>
                            </NavLink>
                        </li>
                    </ul>


                </div>
            </div>


            {/*<div>
                <ul className='mb-2'>
                    <li className='main-page-home'>
                        <NavLink to="/logout" className={liClass}>
                            <span className='menu-svg-warp'>
                                <LogoutOutlinedIcon sx={{ color: iconColor }} />
                            </span>
                            <span className='menu-link-warp'>
                                ออกจากระบบ
                            </span>
                        </NavLink>
                    </li>
                </ul>
                        </div>*/}
        </aside>
    )
}