import { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { QRCodeCanvas } from "qrcode.react";
import Layout from '../../layouts/layout'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const QuestionnairesQrcode = () => {

    const [url, setUrl] = useState("");
    const qrRef = useRef();
    const [sales, setSales] = useState([]);
    const [questions, setQuestions] = useState([]);
    const protenial = [{ id: 'potential', name: 'Potential' }, { id: 'not-potential', name: 'Not potential' }];

    const [selectedSale, setSelectesSale] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedProtenial, setSelectedProtenial] = useState(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get-data-qr`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token')
            }
        }).then(res => {
            setSales(res.data.sales);
            setQuestions(res.data.questionnaire)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const downloadQRCode = (e) => {
        e.preventDefault();
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = url + `-qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        //setUrl("");
    };

    const qrCodeEncoder = (path, value, redirect) => {
        setUrl(`${process.env.REACT_APP_FRONTEND_URL}/questionnaire/${path}/${redirect}?sale=${value}&redirect=${redirect}`);
    };

    const qrcode = (
        <div className="flex justify-center">
            <div style={{ maxWidth: '350px' }} className="mx-auto mt-6 mb-2">
                <QRCodeCanvas
                    id="qrCode"
                    value={url}
                    size={350}
                    bgColor={"#ffffff"}
                    level={"H"}
                />
            </div>
        </div>
    );

    return (
        <Layout mainClass={`bg-slate-50`}>
            <div className='mx-auto flex justify-center items-center w-full' style={{ minHeight: 'calc(100vh - 95px)', maxWidth: '450px' }}>

                <div className="w-full">
                    <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                        <CardContent>
                            <form className="" onSubmit={downloadQRCode}>
                                <div className="">

                                    {questions.length > 0 &&
                                        <div className="mb-4">
                                            <Autocomplete
                                                options={questions}
                                                getOptionLabel={(option) => option.q_title}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField {...params} label="เลือกแบบสอบถาม" />}
                                                value={selectedQuestion}
                                                onChange={(event, newValue) => {
                                                    if (newValue === null) {
                                                        setUrl("");
                                                        setSelectedQuestion(null);
                                                    } else {
                                                        setSelectedQuestion(newValue);
                                                        qrCodeEncoder(newValue.q_code, selectedSale?.id, selectedProtenial?.id)
                                                    }
                                                }}
                                            />
                                        </div>
                                    }

                                    {sales.length > 0 &&
                                        <div className="mb-4">
                                            <Autocomplete
                                                options={sales}
                                                getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField {...params} label="เลือกพนักงานขาย" />}
                                                value={selectedSale}
                                                onChange={(event, newValue) => {
                                                    if (newValue === null) {
                                                        setUrl("");
                                                        setSelectesSale(null);
                                                    } else {
                                                        setSelectesSale(newValue);
                                                        qrCodeEncoder(selectedQuestion?.q_code, newValue.id, selectedProtenial?.id)
                                                    }
                                                }}
                                            />
                                        </div>
                                    }

                                    <div className="mb-4">
                                        <Autocomplete
                                            options={protenial}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="เลือกเกรดลูกค้า" />}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            value={selectedProtenial}
                                            onChange={(event, newValue) => {
                                                if (newValue === null) {
                                                    setUrl("");
                                                    setSelectedProtenial(null);
                                                } else {
                                                    setSelectedProtenial(newValue);
                                                    qrCodeEncoder(selectedQuestion?.q_code, selectedSale?.id, newValue.id)
                                                }
                                            }}
                                        />
                                    </div>


                                </div>
                                {selectedSale && selectedQuestion && selectedProtenial ? (
                                    <div className="">
                                        <div ref={qrRef} className="">

                                            {qrcode}

                                            <button type="submit" disabled={!url} className="text-white bg-col1 hover:bg-yellow focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 justify-center  inline-flex items-center w-full mt-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                                ดาวน์โหลด QR Code
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center h-96">
                                        <div className="text-center">
                                            <h3 className="font-medium text-gray-900 mt-4">เลือกแบบสอบถามและพนักงานขาย</h3>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </CardContent>
                    </Card>
                </div>

            </div>
        </Layout>
    );


}

export default QuestionnairesQrcode;