import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function CustomerVisitsFromSurvey() {

    const [questionnaires, setQuestionnaires] = useState([]);
    const [filterQuestion, setFilterQuestion] = useState(null);
    const [groupBy, setGroupBy] = useState('day');
    const [reportData, setReportData] = useState(null);
    //const [staticData, setStaticData] = useState(null);
    const [isSetStaticData, setIsSetStaticData] = useState(false);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().subtract(6, 'day').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().subtract(0, 'day').format('YYYY-MM-DD')),
    });
    const [users, setUsers] = useState([]);
    const [filterUser, setFilterUser] = useState(null);

    const formatDateInThai = date => {
        // check date 
        if (!dayjs(date).isValid()) {
            return '';
        }

        // if year month
        if (date === dayjs(date).format('YYYY-MM')) {
            return dayjs(date).locale('th').format('MMM'); // Adjust the format as needed
        } else {
            return dayjs(date).locale('th').format('DD MMM'); // Adjust the format as needed
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
            setFilterQuestion(res.data.data[0].questionnaire_id);
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {

        const fetchUsers = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users/get/1`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setUsers(data.data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchUsers();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/customer-visits-from-survey-by-questionnaire`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        groupBy: groupBy,
                        compare: true,
                        questionnaire_id: filterQuestion,
                        userId: filterUser,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);
                if (!isSetStaticData) {
                    //setStaticData(data);
                    setIsSetStaticData(true);
                }

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        if (questionnaires.length > 0) {
            fetchData();
        }

    }, [filterDate, groupBy, filterQuestion, questionnaires, isSetStaticData, filterUser]);

    const handleQuestionChange = (event) => {
        setFilterQuestion(event.target.value);
    };

    const exportToExcel = () => {
        if (!reportData) return;

        const data = reportData.current_year.map((item, index) => {
            return {
                'วัน / เดือน': formatDateInThai(item.group_date),
                'ล่าสุด': item.total_responses,
                'เดือนก่อน': reportData.r_1_month_ago[index]?.total_responses || "0",
                '3 เดือนก่อน': reportData.r_3_months_ago[index]?.total_responses || "0",
                'ปีก่อน': reportData.last_year[index]?.total_responses || "0",
            }
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `ข้อมูลลูกค้าเข้าชมจากแบบสอบถาม ${dayjs(filterDate.startDate).format('DD-MM-YYYY')} ถึง ${dayjs(filterDate.endDate).format('DD-MM-YYYY')} โครงการ ${questionnaires.find(item => item.questionnaire_id === filterQuestion).q_title}.xlsx`);
    }

    // Function to disable future dates
    const shouldDisableFutureDates = (date) => {
        // Convert the input date and current date to start of their respective days for comparison
        const currentDate = dayjs().startOf('day');
        const inputDate = dayjs(date).startOf('day');

        // Return true if the input date is after the current date, disabling it in the picker
        return inputDate.isAfter(currentDate);
    };

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    {questionnaires.length > 0 &&
                        <Select
                            value={filterQuestion}
                            onChange={handleQuestionChange}
                            size='small'
                            sx={{
                                backgroundColor: '#fff',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e5e5ea',
                                }
                            }}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.questionnaire_id} value={questionnaire.questionnaire_id}>
                                    {questionnaire.q_title}
                                </MenuItem>
                            ))}
                        </Select>
                    }

                    <select
                        className='border border-gray-300 rounded-md px-2 py-1'
                        onChange={(e) => {
                            setFilterUser(e.target.value === 'all' ? null : e.target.value);
                        }}
                    >
                        <option value='all'>พนักงานขายทั้งหมด</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
                        ))}
                    </select>

                    <Select
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value)}
                        size='small'
                        sx={{
                            width: 200,
                            backgroundColor: '#fff',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e5e5ea',
                            }
                        }}
                    >
                        <MenuItem value={'day'}>รายวัน</MenuItem>
                        <MenuItem value={'month'}>รายเดือน</MenuItem>
                    </Select>

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                        shouldDisableDate={shouldDisableFutureDates}
                    />

                    <Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">

                    {/*<Card className='my-4'>
                        <CardContent>
                            <h3 className='text-xl font-bold mb-4 text-center'>7 วันล่าสุด</h3>
                            {staticData && (
                                <Bar
                                    data={{
                                        labels: staticData.current_year.map(item => formatDateInThai(item.group_date)),
                                        datasets: [{
                                            label: 'ล่าสุด',
                                            data: staticData.current_year.map(item => item.total_responses),
                                            backgroundColor: `#FF6384`,
                                            borderColor: `#FF6384`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'สัปดาห์ก่อน',
                                            data: staticData.r_7_days_ago.map(item => item.total_responses),
                                            backgroundColor: `#36A2EB`,
                                            borderColor: `#36A2EB`,
                                            borderWidth: 1
                                        }]
                                    }}
                                    options={{
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    title: function (tooltipItems) {
                                                        // Display the date of the current bar
                                                        //let title = dayjs(staticData.current_year[tooltipItems[0].dataIndex].group_date).locale('th').format('DD MMM');
                                                        return '';
                                                    },
                                                    label: function (context) {
                                                        // Get the label for the current dataset
                                                        let label = context.dataset.label;
                                                        let value = context.raw;

                                                        if (context.datasetIndex === 1) {
                                                            // For the 'Previous week' dataset, adjust the date to match the corresponding date from the current week
                                                            label = dayjs(staticData.current_year[context.dataIndex].group_date).subtract(7, 'day').locale('th').format('DD MMM');
                                                        } else {
                                                            // For the 'Current week' dataset, use the current date
                                                            label = dayjs(staticData.current_year[context.dataIndex].group_date).locale('th').format('DD MMM');
                                                        }

                                                        return `${label}: ${value}`;
                                                    },
                                                    labelColor: function (context) {
                                                        return {
                                                            borderColor: 'transparent',
                                                            backgroundColor: context.dataset.backgroundColor,
                                                        };
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                />
                            )}
                        </CardContent>
                                </Card>*/}

                    <Card className='my-4'>
                        <CardContent>
                            {reportData && (
                                <Bar
                                    data={{
                                        labels: reportData.current_year.map(item => formatDateInThai(item.group_date)),
                                        datasets: [{
                                            label: 'ล่าสุด',
                                            data: reportData.current_year.map(item => item.total_responses),
                                            backgroundColor: `#FF6384`,
                                            borderColor: `#FF6384`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'เดือนก่อน',
                                            data: reportData.r_1_month_ago.map(item => item.total_responses),
                                            backgroundColor: `#FFCE56`,
                                            borderColor: `#FFCE56`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: '3 เดือนก่อน',
                                            data: reportData.r_3_months_ago.map(item => item.total_responses),
                                            backgroundColor: `#4BC0C0`,
                                            borderColor: `#4BC0C0`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'ปีก่อน',
                                            data: reportData.last_year.map(item => item.total_responses),
                                            backgroundColor: `#9966FF`,
                                            borderColor: `#9966FF`,
                                            borderWidth: 1
                                        }]
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <table className='w-full border-collapse border border-gray-300'>
                                <thead>
                                    <tr>
                                        <th className='border border-gray-200 px-4 py-2 text-left'>วัน / เดือน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ล่าสุด</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>เดือนก่อน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>3 เดือนก่อน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ปีก่อน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData && reportData.current_year.map((row, index) => (
                                        <tr key={row.group_date} className='hover:bg-slate-50'>
                                            <td className='border border-gray-200 px-4 py-2'>{formatDateInThai(row.group_date)}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{row.total_responses}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago[index]?.total_responses || "0"}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_months_ago[index]?.total_responses || "0"}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year[index]?.total_responses || "0"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </div>
            </div>

        </Layout >
    );
}
