import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import Layout from '../../layouts/layout';
import Button from '@mui/material/Button';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const AddQuestionnaires = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [formTitle, setFormTitle] = useState('');
    const [elements, setElements] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [clickCount, setClickCount] = useState(0);

    const handleSubmit = (e) => {

        if (formTitle === '') {
            setSnackbarMsg({
                open: true,
                message: 'กรุณากรอกชื่อแบบสอบถาม',
                severity: 'error'
            });
            return;
        }

        if (elements.length === 0) {
            setSnackbarMsg({
                open: true,
                message: 'กรุณาเพิ่มคำถาม',
                severity: 'error'
            });
            return;
        }

        let error = false;
        let errorMessages = [];
        elements.forEach(element => {

            //console.log(element);

            if (element.type === 'radio' || element.type === 'checkbox' || element.type === 'select') {
                if (!element.options || element.options.length === 0) {
                    error = true;
                    setSelectedElement(element);
                    errorMessages = [...errorMessages, 'กรุณาเพิ่มตัวเลือก'];
                }
                // Check options label empty
                element.options.forEach(option => {
                    if (option.label === '') {
                        error = true;
                        setSelectedElement(element);
                        errorMessages = [...errorMessages, 'กรุณากรอก Label ของตัวเลือก'];
                    }
                });
            }
            // Check label empty
            if (element.label === '') {
                error = true;
                setSelectedElement(element);
                errorMessages = [...errorMessages, 'กรุณากรอก Label'];
            }
        });

        if (error) {
            setSnackbarMsg({
                open: true,
                message: errorMessages.join('\n') || 'กรุณากรอกข้อมูลให้ครบถ้วน',
                severity: 'error'
            });
            return;
        }

        setLoading(true);

        //console.log(elements);
        //return;

        
        axios.request({
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/addnew`,
            data: {
                title: formTitle,
                elements: elements
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token')
            }
        }).then(res => {
            setLoading(false);
            setSnackbarMsg({
                open: true,
                message: 'บันทึกแบบสอบถามสำเร็จ',
                severity: 'success'
            });
            navigate(`/questionnaires/update/${res.data.id}`, {
                state: { snackbarMsg: { open: true, message: 'บันทึกแบบสอบถามสำเร็จ', severity: 'success' } }
            });
        }).catch(err => {
            console.log(err);
            setLoading(false);

            // If 401 Unauthorized Remove Token and Redirect to Login
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_role');
                window.location.href = '/login';
                return;
            }

            let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (err.response && err.response.data.error) {
                const errors = err.response.data.error;
                const firstErrorKey = Object.keys(errors)[0];
                errorMessage = errors[firstErrorKey];
            }

            setSnackbarMsg({
                open: true,
                message: errorMessage,
                severity: 'error'
            });
        });
        
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const initialCopy = parseInt(params.get('copy')) || null;
        if (initialCopy) {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/single/${initialCopy}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('admin_token')}`
                }
            }).then(res => {
                setFormTitle(`สำเนา ${res.data.data.q_title}`);
                setElements(res.data.data.form_json);
            }).catch(
                err => setSnackbarMsg({
                    open: true,
                    message: 'เกิดข้อผิดพลาดในการโหลดข้อมูล กรุณาติดต่อผู้ดูแลระบบ',
                    severity: 'error'
                })
            );
        }
    }, [location.search]);

    useEffect(() => {
        setSelectedElement(null);
    }, [clickCount]);

    return (
        <Layout mainClass={`bg-slate-50`}>
            <div className="">
                <div className='flex justify-between mb-8'>
                    <div className='flex gap-4 items-center'>
                        <Link to='/questionnaires' className='btn btn-primary'><ArrowBackOutlinedIcon /></Link>
                        <h1 className="text-xl font-semibold">สร้างแบบสอบถาม</h1>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        เผยแพร่
                    </Button>
                </div>

                <div className='mb-4'>
                    <TextField
                        type="text"
                        value={formTitle}
                        name="label"
                        onChange={(e) => setFormTitle(e.target.value)}
                        label="ชื่อแบบสอบถาม"
                        fullWidth
                    />
                </div>
                <div className='mb-4'>
                    <FormBuilder
                        elements={elements}
                        setElements={setElements}
                        selectedElement={selectedElement}
                        setSelectedElement={setSelectedElement}
                        clickCount={clickCount}
                        setClickCount={setClickCount}
                    />
                </div>
            </div>

            <Snackbar
                open={snackbarMsg.open}
                autoHideDuration={6000}
                onClose={() => setSnackbarMsg({ ...snackbarMsg, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbarMsg({ ...snackbarMsg, open: false })}
                    severity={snackbarMsg.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMsg.message}
                </MuiAlert>
            </Snackbar>
        </Layout>
    );
}
export default AddQuestionnaires;