import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import Avatar from '@mui/material/Avatar';
import NotificationList from '../components/NotificationList';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

export default function Header() {

    return (
        <header className='sticky top-0 bg-white z-40'>
            <div className='flex justify-between items-center px-8 py-2 w-full border-b'>
                <div>
                    <button onClick={() => {
                        if (localStorage.getItem('collapsed') === 'collapsed') {
                            document.querySelector('.main-side-bar').classList.remove('collapsed');
                            localStorage.setItem('collapsed', 'false');
                        } else {
                            document.querySelector('.main-side-bar').classList.add('collapsed');
                            localStorage.setItem('collapsed', 'collapsed');
                        }
                    }}>
                        <ClearAllOutlinedIcon />
                    </button>
                </div>
                <div className='flex justify-end gap-4 items-center'>
                    <Link to='/activity-calendar'>
                        <IconButton
                            sx={{ backgroundColor: '#f5f5f5' }}
                        >
                            <CalendarMonthOutlinedIcon />
                        </IconButton>
                    </Link>
                    <NotificationList />
                    <Avatar>{localStorage.getItem('admin_username')?.charAt(0)}</Avatar>
                </div>
            </div>
        </header>
    );
}