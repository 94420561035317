import { useState, useEffect, useContext, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../layouts/layout';
import LoadingContext from '../../contexts/LoadingContext';
import { NavigationContext } from '../../contexts/NavigationContext';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const UpdateOpportunities = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { hasUnsavedChanges, setHasUnsavedChanges, requestNavigation } = useContext(NavigationContext);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [opportunities, setOpportunities] = useState(null);
    const [activities, setActivities] = useState([]);
    const [customerDetail, setCustomerDetail] = useState(null);
    const [questionsAnswers, setQuestionsAnswers] = useState([]);
    const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
    const [awsCustomerId, setAwsCustomerId] = useState('');
    const [originalAwsCustomerId, setOriginalAwsCustomerId] = useState('');
    const [endbleEditAwsCustomerId, setEndbleEditAwsCustomerId] = useState(false);
    const initializeActivity = {
        activity_id: null,
        activity_title: '',
        activity_description: '',
        activity_type: 'ติดตาม',
        scheduled_date: null,
        notify_time: null,
        isSuccess: false,
        opportunity_id: id,
    };
    const [activity, setActivity] = useState(initializeActivity);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [opTitle, setOpTitle] = useState('');
    const opportunityStatusColors = {
        "New": "#FFD700",
        //"Qualified": "#32CD32",
        //"Needs Analysis": "#1E90FF",
        "Proposal/Price Quote": "#FFA500",
        //"Negotiation": "#FF6347",
        //"Verbal Commitment": "#FFDAB9",
        "Closed – Won": "#008000",
        "Closed – Lost": "#FF0000",
        //"On Hold": "#D3D3D3",
        //"Not Qualified": "#8B0000",
        //"Deferred": "#C71585",
        "Transferred": "#20B2AA",
        "Reserve": "#4B0082",
        "Make Contract": "#32CD32",
        "Returning Customer": "#1E90FF",
    };
    const opportunityStatusTranslate = {
        "New": "ลูกค้าใหม่",
        //"Qualified": "ผ่านการรับรอง",
        //"Needs Analysis": "ต้องการการวิเคราะห์",
        "Proposal/Price Quote": "เสนอราคา",
        //"Negotiation": "เจรจาต่อรอง",
        //"Verbal Commitment": "ตกลงซื้อ (ยังไม่ทำเอกสาร)",
        "Closed – Won": "ปิดการขาย - สำเร็จ",
        "Closed – Lost": "ปิดการขาย - ล้มเหลว",
        //"On Hold": "พักการขาย",
        //"Not Qualified": "ไม่ผ่านการรับรอง",
        //"Deferred": "เลื่อน",
        "Transferred": "โอนแล้ว",
        "Reserve": "จอง",
        "Make Contract": "ทำสัญญา",
        "Returning Customer": "ชมซ้ำ",
    }
    const [alert, setAlert] = useState({
        open: false,
        type: 'success',
        msg: '',
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/single/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
        }).then((response) => {
            setOpportunities(response.data.opportunity);
            setActivities(response.data.activities);
            setCustomerDetail(response.data.customer);
            setQuestionsAnswers(response.data.questionAndAnswer);
            setOpTitle(response.data.questionTitle);
            setAwsCustomerId(response.data.customer.ams_customer);
            setOriginalAwsCustomerId(response.data.customer.ams_customer);
        }).catch((err) => {
            setIsLoading(false);
            if (err.response.data.error) {

                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        })
    }, [id, setIsLoading]);

    useEffect(() => {
        window.onbeforeunload = hasUnsavedChanges ? () => true : undefined;
        return () => {
            window.onbeforeunload = undefined;
        };
    }, [hasUnsavedChanges]);

    const validateActivityInput = () => {
        if (!activity.activity_title) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกชื่อการติดตาม',
            });
            return false;
        }
        if (!activity.activity_description) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกรายละเอียด',
            });
            return false;
        }
        if (!activity.activity_type) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกประเภท',
            });
            return false;
        }
        return true;
    }

    const handleAddnewOrUpdate = (type) => {
        if (validateActivityInput()) {
            setIsLoading(true);
            if (type === 'add') {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/activity/${id}`, activity, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
                    },
                }).then((response) => {
                    //setActivities([activity, ...activities]);
                    setActivities(response.data.activities);
                    setAlert({
                        open: true,
                        type: 'success',
                        msg: 'เพิ่มการติดตามสำเร็จ',
                    });
                    setOpenAddDialog(false);
                    setIsLoading(false);
                }).catch((err) => {
                    setIsLoading(false);
                    if (err.response.data.error) {

                        setAlert({
                            open: true,
                            type: 'error',
                            msg: err.response.data.error,
                        });
                    }
                })
            } else if (type === 'update') {
                axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/activity/${activity.activity_id}`, activity, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
                    },
                }).then((response) => {
                    /*
                    const newActivities = activities.map((item) => {
                        if (item.activity_id === activity.activity_id) {
                            return activity;
                        }
                        return item;
                    });
                    */
                    setActivities(response.data.activities);
                    setAlert({
                        open: true,
                        type: 'success',
                        msg: 'แก้ไขการติดตามสำเร็จ',
                    });
                    setOpenAddDialog(false);
                    setIsLoading(false);
                }).catch((err) => {
                    setIsLoading(false);
                    if (err.response.data.error) {

                        setAlert({
                            open: true,
                            type: 'error',
                            msg: err.response.data.error,
                        });
                    }
                })
            }
            setActivity(initializeActivity);
        }
    }

    const handleDeleteActivity = () => {
        setIsLoading(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/activity/${activity.activity_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
        }).then((response) => {
            const newActivities = activities.filter((item) => item.activity_id !== activity.activity_id);
            setActivities(newActivities);
            setActivity(initializeActivity);
            setAlert({
                open: true,
                type: 'success',
                msg: 'ลบการติดตามสำเร็จ',
            });
            setOpen(false);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            if (err.response.data.error) {

                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        })
    }

    const handleUpdateOpportunity = () => {
        setIsLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/update/${id}`, opportunities, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
        }).then((response) => {
            setAlert({
                open: true,
                type: 'success',
                msg: 'แก้ไขข้อมูลสำเร็จ',
            });
            setIsLoading(false);
            setHasUnsavedChanges(false);
        }).catch((err) => {
            setIsLoading(false);
            if (err.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        })
    }

    const handleUpdateAwsCustomerId = () => {

        if (!awsCustomerId) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกหมายเลขลูกค้า',
            });
            return;
        }


        setIsLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/quick-update-aws-id`,
            {
                opportunity_id: id,
                aws_customer_id: awsCustomerId,
            }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
        }).then((response) => {
            setAlert({
                open: true,
                type: 'success',
                msg: 'แก้ไขข้อมูลสำเร็จ',
            });
            setEndbleEditAwsCustomerId(false);
            setOriginalAwsCustomerId(awsCustomerId);
            setHasUnsavedChanges(false);
        }).catch((err) => {
            if (err.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }


    return (
        <Layout mainClass='bg-gray-100'>
            {opportunities &&
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={dayjs.locale('th')}>
                    <div className='' style={{ minHeight: 'calc(100vh - 95px)' }}>
                        <div className='flex justify-between mb-8'>
                            <div className='flex gap-4 items-center'>
                                <button onClick={() => requestNavigation('/opportunities')} className='btn btn-primary'>
                                    <ArrowBackOutlinedIcon />
                                </button>
                                <h1 className="text-xl font-semibold">การขาย: {opTitle}</h1>
                            </div>
                            <div className='flex gap-6 items-end'>
                                <FormControl
                                    size='small'
                                    variant='standard'
                                >
                                    <InputLabel>สถานะ</InputLabel>
                                    <Select
                                        value={opportunities.op_status || ""}
                                        onChange={(e) => {
                                            setOpportunities({ ...opportunities, op_status: e.target.value });
                                            setHasUnsavedChanges(true);
                                        }}
                                        label="สถานะ"
                                        size='small'
                                        sx={{ width: 200 }}
                                        variant='standard'
                                    >
                                        {Object.keys(opportunityStatusColors).map((key) => (
                                            <MenuItem value={key} key={key}>
                                                <div className='flex items-center gap-2'>
                                                    <div className='w-3 h-3 rounded-full' style={{ backgroundColor: opportunityStatusColors[key] }}></div>
                                                    <span>{opportunityStatusTranslate[key]}</span>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <DatePicker
                                    value={opportunities.expected_close_date ? dayjs(opportunities.expected_close_date) : null}
                                    onChange={(newValue) => {
                                        setOpportunities({ ...opportunities, expected_close_date: newValue });
                                        setHasUnsavedChanges(true);
                                    }}
                                    format="DD/MM/YYYY"
                                    label="วันที่คาดว่าจะปิดการขาย"
                                    size='small'
                                    slotProps={{ textField: { variant: "standard" } }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpdateOpportunity}
                                    disabled={isLoading}
                                >
                                    บันทึกการเปลี่ยนแปลง
                                </Button>
                            </div>
                        </div>

                        <div className='grid grid-cols-12 gap-4 pb-8'>
                            <div className='col-span-4'>
                                <div className='bg-white rounded-lg shadow'>
                                    <div className='flex justify-between items-center border-b p-4'>
                                        <h2 className='text-xl font-bold'>ข้อมูลการขาย</h2>
                                    </div>
                                    <div className='p-4'>

                                        <div className='border rounded-lg p-3'>
                                            {/*<h3 className='text-lg  mb-4'>สถานะการติดตาม</h3>*/}
                                            <label className='text-sm text-gray-400 whitespace-nowrap'>Title</label>
                                            <input
                                                type='text'
                                                className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                value={opportunities.op_title || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_title: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>Description</label>
                                            <textarea
                                                className='w-full border border-gray-200 rounded-lg p-2'
                                                rows={4}
                                                value={opportunities.op_description || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_description: e.target.value })
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>Comment วันที่เข้าชม</label>
                                            <textarea
                                                className='w-full border border-gray-200 rounded-lg p-2'
                                                rows={4}
                                                value={opportunities.visiting_comment || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, visiting_comment: e.target.value })
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            {/*
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div>
                                                    <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>สถานะเกรดลูกค้า</label>
                                                    <input
                                                        type='text'
                                                        className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                        value={opportunities.op_customer_grade || ""}
                                                        onChange={(e) => {
                                                            setOpportunities({ ...opportunities, op_customer_grade: e.target.value })
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>สถานะเกรดจากวันเข้าชม</label>
                                                    <input
                                                        type='text'
                                                        className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                        value={opportunities.op_grade_after_view || ""}
                                                        onChange={(e) => {
                                                            setOpportunities({ ...opportunities, op_grade_after_view: e.target.value });
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                                    */}

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>แบบบ้านที่สนใจ</label>
                                            <input
                                                type='text'
                                                className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                value={opportunities.op_interesting || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_interesting: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>โครงการที่เปรียบเทียบ</label>
                                            <input
                                                type='text'
                                                className='w-full border border-gray-200 rounded-lg p-2'
                                                value={opportunities.op_ref || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_ref: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />


                                            {['Transferred', 'Reserve', 'Closed – Won'].includes(opportunities.op_status) && (
                                                <>
                                                    <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>สาเหตุที่ปิดการขายได้</label>
                                                    <input
                                                        type='text'
                                                        className='w-full border border-gray-200 rounded-lg p-2'
                                                        value={opportunities.reason_close_sale || ""}
                                                        onChange={(e) => {
                                                            setOpportunities({ ...opportunities, reason_close_sale: e.target.value });
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                    />
                                                </>
                                            )}


                                            {['Closed – Lost'].includes(opportunities.op_status) && (
                                                <>
                                                    <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>สาเหตุที่ปิดการขายไม่ได้</label>
                                                    <input
                                                        type='text'
                                                        className='w-full border border-gray-200 rounded-lg p-2'
                                                        value={opportunities.reason_not_close_sale || ""}
                                                        onChange={(e) => {
                                                            setOpportunities({ ...opportunities, reason_not_close_sale: e.target.value });
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                    />
                                                </>
                                            )}



                                        </div>




                                        <div className='border rounded-lg p-3 mt-4'>
                                            <h3 className='text-lg'>สถานะการซื้อขาย</h3>

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>แปลงที่จอง</label>
                                            <input
                                                type='text'
                                                className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                value={opportunities.reserved_plot || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, reserved_plot: e.target.value })
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>ราคาขายในระบบ</label>
                                            <input
                                                type='number'
                                                className='w-full border border-gray-200 rounded-lg p-2 mb-4'
                                                value={opportunities.selling_price || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, selling_price: e.target.value })
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4'>โปรโมชั่นที่ให้ลูกค้า</label>
                                            <input
                                                type='text'
                                                className='w-full border border-gray-200 rounded-lg p-2'
                                                value={opportunities.selling_promotion || ""}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, selling_promotion: e.target.value })
                                                    setHasUnsavedChanges(true);
                                                }}
                                            />

                                            <label className='text-sm text-gray-400 whitespace-nowrap mt-4 mb-2 block'>เกรดการซื้อขาย</label>
                                            {
                                                /*
                                                เกรด A:โอนกรรมสิทธ์แล้ว B: อยู่ระหว่างผ่อนดาวน์ รอบ้าน รอโอน C:ยกเลิกสาเหตุอื่นๆ ไม่ใช่เรื่องกู้ธนาคารไม่ผ่าน D:กู้ธนาคารไม่ผ่าน
                                                */
                                            }
                                            <Select
                                                value={opportunities.sale_status_grade || "ยังไม่จัดเกรด"}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, sale_status_grade: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="เกรดการซื้อขาย"
                                                size='small'
                                                fullWidth
                                                variant='standard'
                                            >
                                                <MenuItem value='ยังไม่จัดเกรด'>ยังไม่จัดเกรด</MenuItem>
                                                <MenuItem value='A'>A: โอนกรรมสิทธ์แล้ว</MenuItem>
                                                <MenuItem value='B'>B: อยู่ระหว่างผ่อนดาวน์ รอบ้าน รอโอน</MenuItem>
                                                <MenuItem value='C'>C: ยกเลิกสาเหตุอื่นๆ ไม่ใช่เรื่องกู้ธนาคารไม่ผ่าน</MenuItem>
                                                <MenuItem value='D'>D: กู้ธนาคารไม่ผ่าน</MenuItem>
                                            </Select>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='bg-white rounded-lg shadow'>
                                    <div className='flex justify-between items-center border-b p-4'>
                                        <h2 className='text-xl font-bold'>การติดตาม</h2>
                                        <IconButton
                                            onClick={() => {
                                                setOpenAddDialog(true);
                                                setActivity(initializeActivity);
                                            }}
                                            sx={{
                                                color: '#333',
                                                backgroundColor: '#e5e7eb',
                                                '&:hover': {
                                                    backgroundColor: '#e5e7eb',
                                                }
                                            }}
                                        >
                                            <AddOutlinedIcon />
                                        </IconButton>
                                    </div>

                                    {/** <div className='p-4 grid grid-cols-2 gap-4 gap-x-0'>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>สถานะเกรดลูกค้า </label>
                                      
                                         * 
                                         * สถานะเกรดลูกค้า ( A:จองแล้ว B:สนใจติดตามได้C:ชะลอการซื้อ D:ไม่สนใจ ไม่ซื้อ)
                                         * 
                                         
                                        <div className='border-b pb-4'>
                                            <Select
                                                value={opportunities.activity_grade || 'ยังไม่จัดเกรด'}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, activity_grade: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="สถานะเกรดลูกค้า "
                                                size='small'
                                                sx={{ width: 200 }}
                                                variant='standard'
                                            >
                                                <MenuItem value='ยังไม่จัดเกรด'>ยังไม่จัดเกรด</MenuItem>
                                                <MenuItem value='A'>A: จองแล้ว</MenuItem>
                                                <MenuItem value='B'>B: สนใจติดตามได้</MenuItem>
                                                <MenuItem value='C'>C: ชะลอการซื้อ</MenuItem>
                                                <MenuItem value='D'>D: ไม่สนใจ ไม่ซื้อ</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    */}

                                    <ol className="relative p-4 overflow-y-auto" style={{ maxHeight: '75vh' }}>
                                        {activities.map((activity) => (
                                            <li className="pl-4 py-2 hover:bg-slate-50 border-l border-gray-200" key={activity.activity_id}>
                                                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 left-2.5 border border-white"></div>
                                                <div className='flex justify-between items-center'>
                                                    <div>
                                                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 ">
                                                            {dayjs(activity.create_at).format("DD/MM/YYYY HH:mm")}
                                                        </time>
                                                        <h3 className="font-semibold text-gray-900 ">{activity.activity_title}</h3>
                                                        <p className="mb-4 text-base font-normal text-gray-500 ">{activity.activity_description}</p>
                                                    </div>
                                                    <div className='flex gap-2'>
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpenAddDialog(true);
                                                                setActivity({
                                                                    activity_id: activity.activity_id,
                                                                    activity_title: activity.activity_title,
                                                                    activity_description: activity.activity_description,
                                                                    activity_type: activity.activity_type,
                                                                    scheduled_date: activity.scheduled_date,
                                                                    notify_time: activity.notify_time,
                                                                    isSuccess: activity.is_success === 0 ? false : true,
                                                                    opportunity_id: id,
                                                                });
                                                            }}
                                                        >
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen(true);
                                                                setActivity(activity);
                                                            }}
                                                        >
                                                            <CloseOutlinedIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>

                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='bg-white rounded-lg shadow'>
                                    <div className='flex justify-between items-center border-b p-4'>
                                        <h2 className='text-xl font-bold'>ข้อมูลลูกค้า </h2>
                                    </div>
                                    <div className='p-4 grid grid-cols-2 gap-4 gap-x-0'>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b pt-2.5 pb-4'>หมายเลขลูกค้า</label>
                                        <div className='flex gap-2 border-b items-center'>
                                            <div className=''>
                                                {
                                                    endbleEditAwsCustomerId ?
                                                        <input
                                                            type='text'
                                                            className='w-full border border-gray-200 rounded-lg p-2'
                                                            value={awsCustomerId}
                                                            onChange={(e) => {
                                                                setAwsCustomerId(e.target.value);
                                                                setHasUnsavedChanges(true);
                                                            }}
                                                        />
                                                        :
                                                        <p className='text-gray-900 font-medium'>{awsCustomerId}</p>
                                                }
                                            </div>
                                            <div className=''>
                                                {
                                                    endbleEditAwsCustomerId ?
                                                        <div className='flex gap-2'>
                                                            <button
                                                                onClick={handleUpdateAwsCustomerId}
                                                                disabled={isLoading}
                                                                className='bg-col1 text-white px-4 py-1 rounded-lg'
                                                            >
                                                                บันทึก
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    setEndbleEditAwsCustomerId(false);
                                                                    setAwsCustomerId(originalAwsCustomerId);
                                                                    setHasUnsavedChanges(false);
                                                                }}
                                                            >
                                                                ยกเลิก
                                                            </button>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEndbleEditAwsCustomerId(true)}
                                                        >
                                                            <EditOutlinedIcon sx={{ fontSize: '1.2rem' }} />
                                                        </IconButton>
                                                }
                                            </div>
                                        </div>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>รูปแบบการเข้าชม</label>
                                        <div className='border-b pb-4'>
                                            <Select
                                                value={opportunities.op_new_view === 1 ? 'ชมใหม่' : opportunities.op_return_view === 1 ? 'ชมซ้ำ' : opportunities.op_call_in === 1 ? 'โทรเข้า' : 'ไม่กำหนด'}
                                                onChange={(e) => {
                                                    if (e.target.value === 'ชมใหม่') {
                                                        setOpportunities({ ...opportunities, op_new_view: 1, op_return_view: 0, op_call_in: 0 });
                                                    } else if (e.target.value === 'ชมซ้ำ') {
                                                        setOpportunities({ ...opportunities, op_return_view: 1, op_new_view: 0, op_call_in: 0 });
                                                    } else if (e.target.value === 'โทรเข้า') {
                                                        setOpportunities({ ...opportunities, op_call_in: 1, op_new_view: 0, op_return_view: 0 });
                                                    }
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="รูปแบบการเข้าชม"
                                                size='small'
                                                sx={{ width: 200 }}
                                                variant='standard'
                                            >
                                                <MenuItem value='ไม่กำหนด'>ไม่กำหนด</MenuItem>
                                                <MenuItem value='ชมใหม่'>ชมใหม่</MenuItem>
                                                <MenuItem value='ชมซ้ำ'>ชมซ้ำ</MenuItem>
                                                <MenuItem value='โทรเข้า'>โทรเข้า</MenuItem>
                                            </Select>
                                        </div>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>Potential?</label>
                                        <div className='border-b pb-4'>
                                            <Select
                                                value={opportunities.is_potential || 'ไม่กำหนด'}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, is_potential: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="Potential?"
                                                size='small'
                                                sx={{ width: 200 }}
                                                variant='standard'
                                            >
                                                <MenuItem value='ไม่กำหนด'>ไม่กำหนด</MenuItem>
                                                <MenuItem value='potential'>Potential</MenuItem>
                                                <MenuItem value='Not potential'>Not Potential</MenuItem>
                                            </Select>
                                        </div>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>สถานะเกรดลูกค้า</label>
                                        <div className='border-b pb-4'>
                                            <Select
                                                value={opportunities.op_customer_grade || 'ยังไม่จัดเกรด'}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_customer_grade: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="สถานะเกรดลูกค้า"
                                                size='small'
                                                sx={{ width: 200 }}
                                                variant='standard'
                                            >
                                                <MenuItem value='ยังไม่จัดเกรด'>ยังไม่จัดเกรด</MenuItem>
                                                <MenuItem value='A'>A: จองแล้ว</MenuItem>
                                                <MenuItem value='B'>B: สนใจติดตามได้</MenuItem>
                                                <MenuItem value='C'>C: ชะลอการซื้อ</MenuItem>
                                                <MenuItem value='D'>D: ไม่สนใจ ไม่ซื้อ</MenuItem>
                                            </Select>
                                        </div>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>สถานะเกรดจากวันเข้าชม</label>
                                        <div className='border-b pb-4'>
                                            <Select
                                                value={opportunities.op_grade_after_view || 'ยังไม่จัดเกรด'}
                                                onChange={(e) => {
                                                    setOpportunities({ ...opportunities, op_grade_after_view: e.target.value });
                                                    setHasUnsavedChanges(true);
                                                }}
                                                label="สถานะเกรดลูกค้า"
                                                size='small'
                                                sx={{ width: 200 }}
                                                variant='standard'
                                            >
                                                <MenuItem value='ยังไม่จัดเกรด'>ยังไม่จัดเกรด</MenuItem>
                                                <MenuItem value='A'>A: งบถึง รายได้กู้ได้ ยังไม่พร้อมตัดสินใจ เปรียบเทียบก่อน</MenuItem>
                                                <MenuItem value='B'>B: กู้ได้ แต่ไม่สนใจ</MenuItem>
                                                <MenuItem value='C'>C: งบไม่ถึง เกินงบ</MenuItem>
                                                <MenuItem value='D'>D: ไม่สามารถกู้ได้</MenuItem>
                                            </Select>
                                        </div>

                                        {/*
                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>ชื่อลูกค้า</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_name_title} {customerDetail.c_first_name} {customerDetail.c_Last_name}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>โทรศัพท์</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_phone}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>อีเมล</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_email ? customerDetail.c_email : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>ไอดีไลน์</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_line_id ? customerDetail.c_line_id : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>ที่อยู่</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>
                                            {customerDetail.c_address} {customerDetail.c_tambon} {customerDetail.c_amphure} {customerDetail.c_province} {customerDetail.c_zipcode}
                                        </p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>อายุ</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_age ? customerDetail.c_age : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>รายได้</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_income ? customerDetail.c_income : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>อาชีพ</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_career ? customerDetail.c_career : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>บริษัท</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_company ? customerDetail.c_company : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>ที่ตั้งบริษัท</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_company_address ? customerDetail.c_company_address : '-'}</p>

                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>เขตบริษัท</label>
                                        <p className='text-gray-900 font-medium border-b pb-4'>{customerDetail.c_company_amphure ? customerDetail.c_company_amphure : '-'}</p>
                                            */}

                                        {questionsAnswers.length > 0 && questionsAnswers.map((item, index) => (
                                            <Fragment key={index}>
                                                {item.question === 'ที่อยู่' ?
                                                    <>
                                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>แขวง / ตำบล</label>
                                                        <p className='text-gray-900 font-medium border-b pb-4'>{item.answer.tambon && item.answer.tambon.name_th ? item.answer.tambon.name_th : '-'}</p>

                                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>เขต / อำเภอ</label>
                                                        <p className='text-gray-900 font-medium border-b pb-4'>{item.answer.amphure && item.answer.amphure.name_th ? item.answer.amphure.name_th : '-'}</p>

                                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>จังหวัด</label>
                                                        <p className='text-gray-900 font-medium border-b pb-4'>{item.answer.province && item.answer.province.name_th ? item.answer.province.name_th : '-'}</p>

                                                        <label className='text-sm text-gray-400 whitespace-nowrap border-b'>รหัสไปรษณีย์</label>
                                                        <p className='text-gray-900 font-medium border-b pb-4'>{item.answer.tambon && item.answer.tambon.zip_code ? item.answer.tambon.zip_code : '-'}</p>
                                                    </>
                                                    :
                                                    (typeof item.answer === 'object' && item.answer !== null) ?
                                                        <>
                                                            <label className='text-sm text-gray-400 whitespace-nowrap border-b truncate pb-4'>{item.question}</label>
                                                            <p className='text-gray-900 font-medium border-b pb-4'>{item.answer ? Object.values(item.answer).join(', ') : '-'}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <label className='text-sm text-gray-400 whitespace-nowrap border-b truncate'>{item.question}</label>
                                                            <p className='text-gray-900 font-medium border-b pb-4'>{item.answer ? item.answer : '-'}</p>
                                                        </>
                                                } </Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    <Dialog
                        open={openAddDialog}
                        onClose={() => {
                            setOpenAddDialog(false);
                            setActivity(initializeActivity);
                        }}
                    >
                        <DialogTitle>
                            {activity.activity_id ? 'แก้ไขการติดตาม' : 'เพิ่มการติดตาม'}
                        </DialogTitle>
                        <DialogContent>
                            <div className='flex flex-col gap-4 w-96 max-w-full'>
                                <TextField
                                    label="ชื่อการติดตาม"
                                    variant="standard"
                                    value={activity.activity_title}
                                    onChange={(e) => setActivity({ ...activity, activity_title: e.target.value })}
                                />
                                <TextField
                                    label="รายละเอียด"
                                    variant="standard"
                                    value={activity.activity_description}
                                    onChange={(e) => setActivity({ ...activity, activity_description: e.target.value })}
                                    multiline
                                    rows={4}
                                />
                                <FormControl
                                    size='small'
                                    variant='standard'
                                >
                                    <InputLabel>ประเภท</InputLabel>
                                    <Select
                                        value={activity.activity_type || ""}
                                        onChange={(e) => setActivity({ ...activity, activity_type: e.target.value })}
                                        label="ประเภท"
                                        size='small'
                                        variant='standard'
                                    >
                                        <MenuItem value='ติดตาม'>ติดตามครั้งที่ 1</MenuItem>
                                        <MenuItem value='ติดตามครั้งที่ 2'>ติดตามครั้งที่ 2</MenuItem>
                                        <MenuItem value='ติดตามครั้งที่ 3'>ติดตามครั้งที่ 3</MenuItem>
                                        <MenuItem value='นัดหมาย'>นัดหมาย</MenuItem>
                                        <MenuItem value='อื่นๆ'>อื่นๆ</MenuItem>
                                    </Select>
                                </FormControl>

                                {activity.activity_type === 'นัดหมาย' && (
                                    <>
                                        <DateTimePicker
                                            label="วันที่นัดหมาย"
                                            value={activity.scheduled_date ? dayjs(activity.scheduled_date) : null}
                                            onChange={(newValue) => {
                                                setActivity({ ...activity, scheduled_date: newValue })
                                            }}
                                            size='small'
                                            slotProps={{ textField: { variant: "standard" } }}
                                            ampm={false}
                                            format='DD/MM/YYYY HH:mm'
                                            timezone='Asia/Bangkok'
                                        />

                                        <DateTimePicker
                                            label="เวลาแจ้งเตือน"
                                            value={activity.notify_time ? dayjs(activity.notify_time) : null}
                                            onChange={(newValue) => {
                                                setActivity({ ...activity, notify_time: newValue })
                                            }}
                                            size='small'
                                            slotProps={{ textField: { variant: "standard" } }}
                                            ampm={false}
                                            format='DD/MM/YYYY HH:mm'
                                            timezone='Asia/Bangkok'
                                        />
                                    </>
                                )}

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={activity.isSuccess || false}
                                            onChange={(e) => setActivity({ ...activity, isSuccess: e.target.checked })}
                                        />
                                    }
                                    label="สำเร็จ"
                                />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setOpenAddDialog(false);
                                setActivity(initializeActivity);
                            }}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                disabled={isLoading}
                                onClick={() => {
                                    if (!activity.activity_id) {
                                        handleAddnewOrUpdate('add');
                                    } else {
                                        handleAddnewOrUpdate('update');
                                    }
                                }}
                            >
                                ยืนยัน
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        onClose={() => setOpen(false)}
                        aria-labelledby="simple-dialog-title"
                        open={open}
                        width='lg'
                        fullWidth
                    >
                        <div className='p-6 text-gray-600'>
                            <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                            <p>ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                            <div className='flex justify-end items-center gap-2 mt-2'>
                                <Button
                                    className='btn btn-secondary'
                                    onClick={() => {
                                        setOpen(false);
                                        setActivity(initializeActivity);
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button className='btn btn-danger' onClick={handleDeleteActivity}>ลบ</Button>
                            </div>
                        </div>
                    </Dialog>

                    <Snackbar
                        className='z-50'
                        open={alert.open}
                        autoHideDuration={6000}
                        onClose={() => setAlert({ ...alert, open: false })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.type} sx={{ width: '100%' }}>
                            {alert.msg}
                        </Alert>
                    </Snackbar>


                </LocalizationProvider>
            }

            {
                /** Check unsaved change before leave page */
            }

            <Dialog
                onClose={() => setOpenUnsavedDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openUnsavedDialog}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>คุณต้องการออกจากหน้านี้หรือไม่</h2>
                    <p>ข้อมูลที่คุณแก้ไขจะไม่ถูกบันทึก</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpenUnsavedDialog(false)
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button className='btn btn-danger' onClick={() => {
                            setOpenUnsavedDialog(false);
                            setHasUnsavedChanges(false);
                            navigate(location.pathname);
                        }}>ออก</Button>
                    </div>
                </div>
            </Dialog>


        </Layout>
    )
}

export default UpdateOpportunities;