import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../layouts/layout";
import { Link } from 'react-router-dom';
import OpportunityList from "../components/OpportunityList";
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../components/predefinedBottomRanges';

const Activities = () => {

    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/get-activities`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }
        fetchData();
    }, [filterDate]);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <div className='flex justify-start items-center gap-4'>
                    <h1 className='text-2xl font-bold'>Activities</h1>
                    <Link to='/activities' className='px-4 py-2 rounded bg-col2 text-white'>กิจกรรม </Link>
                    <Link to='/activity-calendar' className='px-4 py-2 rounded bg-col1 text-white'>ปฏิทินนัดหมาย </Link>
                </div>
                <div className='flex justify-end gap-4'>
                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />
                </div>
            </div>

            {reportData &&
                <div className='max-w-xl mx-auto py-8'>
                    <OpportunityList data={reportData} />
                </div>
            }

        </Layout>
    );
}

export default Activities;