import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function BudgetVsExpenseReport() {

    const [questionnaires, setQuestionnaires] = useState([]);
    const [filterQuestion, setFilterQuestion] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
            setFilterQuestion(res.data.data[0].questionnaire_id);
        }).catch(err => console.log(err));
    }, []);

    const mergeBudgetAndExpense = (budget, expense) => {
        return budget.map(budgetItem => {
            const expenses = expense.filter(expenseItem =>
                expenseItem.questionnaire_id === budgetItem.questionnaire_id &&
                expenseItem.start_date === budgetItem.start_date &&
                expenseItem.end_date === budgetItem.end_date
            );

            return {
                ...budgetItem,
                expenses,
                totalExpense: expenses.reduce((total, curr) => total + parseFloat(curr.amount), 0),
            };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/budget-expense-report`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        questionnaireId: filterQuestion
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                const mergedData = mergeBudgetAndExpense(data.budget, data.expense);
                setReportData(mergedData);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        if (questionnaires.length > 0) {
            fetchData();
        }

    }, [filterDate, filterQuestion, questionnaires]);

    const handleQuestionChange = (event) => {
        setFilterQuestion(event.target.value);
    };

    const addCommars = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const exportToExcel = () => {
        if (!reportData) return;

        // Step 1: Format Data
        const formattedData = reportData.map(item => ({
            "Questionnaire Title": item.q_title,
            "Budget Amount": item.amount,
            "Expense Amount": item.expenses.reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toString()
        }));

        // Step 2: Create a Workbook and Worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

        // Step 3: Export to Excel File
        // Generate an Excel file and trigger download
        XLSX.writeFile(workbook, "BudgetVsExpenseReport.xlsx");
    };


    const generateChartData = () => {
        const labels = reportData?.map(item => item.q_title) || [];
        const budgetData = reportData?.map(item => parseFloat(item.amount)) || [];
        const expenseData = reportData?.map(item => item.expenses.reduce((acc, curr) => acc + parseFloat(curr.amount), 0)) || [];

        return {
            labels,
            datasets: [
                {
                    label: 'Budget',
                    data: budgetData,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'Expense',
                    data: expenseData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Budget vs Expense Report',
            },
        },
    };

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    {questionnaires.length > 0 &&
                        <Select
                            value={filterQuestion}
                            onChange={handleQuestionChange}
                            size='small'
                            sx={{
                                backgroundColor: '#fff',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e5e5ea',
                                }
                            }}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.questionnaire_id} value={questionnaire.questionnaire_id}>
                                    {questionnaire.q_title}
                                </MenuItem>
                            ))}
                        </Select>
                    }

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                    <Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            {reportData && <Bar options={chartOptions} data={generateChartData()} />}
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse border border-gray-300'>
                                    <thead>
                                        <tr>
                                            <th className='order border-gray-200 px-4 py-2 text-left whitespace-nowrap'>Questionnaire Title</th>
                                            <th className='order border-gray-200 px-4 py-2 text-right whitespace-nowrap'>Budget Amount</th>
                                            <th className='order border-gray-200 px-4 py-2 text-right whitespace-nowrap'>Expense Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>{item.q_title}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(item.amount)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(item.expenses.reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toString())}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Layout >
    );
}
