import { useDrop } from 'react-dnd';
import FormPreviewItem from './FormPreviewItem';

const FormPreview = ({ elements, onSelect, onUpdateElements, selectedElement, setElements, elementCounter, setElementCounter, clickCount, setClickCount }) => {

    const handleRemove = (index) => {
        const updatedElements = [...elements];
        updatedElements.splice(index, 1);
        onUpdateElements(updatedElements);
        onSelect(null);
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'NEW_FORM_ELEMENT',
        drop: (draggedItem) => {
            console.log("Dropped:", draggedItem);
            onUpdateElements(prevElements => [...prevElements, { ...draggedItem, id: Date.now() }]);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const moveItem = (fromIndex, toIndex) => {
        const updatedElements = [...elements];
        const [movedItem] = updatedElements.splice(fromIndex, 1);
        updatedElements.splice(toIndex, 0, movedItem);
        onUpdateElements(updatedElements);
    };

    const handleMoveUp = (e, idx) => {
        e.stopPropagation(); // To prevent the parent onClick from being triggered

        if (idx === 0) return; // If it's the first item, no need to move up
        moveItem(idx, idx - 1);
    }

    const handleMoveDown = (e, idx) => {
        e.stopPropagation(); // To prevent the parent onClick from being triggered

        if (idx === elements.length - 1) return; // If it's the last item, no need to move down
        moveItem(idx, idx + 1);
    }

    return (
        <div ref={drop} className="border rounded-lg p-4 grid grid-cols-4 gap-4">
            {elements.map((element, idx) => (
                <FormPreviewItem
                    onSelect={onSelect}
                    key={element.id}
                    element={element}
                    idx={idx}
                    moveItem={moveItem}
                    handleRemove={handleRemove}
                    selectedElement={selectedElement}
                    icon={element.icon}
                    handleMoveUp={handleMoveUp}
                    handleMoveDown={handleMoveDown}
                    setElements={setElements}
                    elementCounter={elementCounter}
                    setElementCounter={setElementCounter}
                    elements={elements}
                    clickCount={clickCount}
                    setClickCount={setClickCount}
                />
            ))}
            {elements.length === 0 && (
                <div className="text-center text-gray-600 py-8 border border-dashed rounded-lg col-span-4">Drag and drop form elements here</div>
            )}
            {canDrop && isOver && (
                <div className="p-4 border-dashed border-2 text-gray-600 rounded-lg col-span-4">Drop here</div>
            )}

          
        </div>
    );
}

export default FormPreview;