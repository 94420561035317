import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from '../../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExportExcel from '../../components/ExportExcel';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';

const Opportunities = () => {
    const navigate = useNavigate();
    const opportunityStatusColors = {
        "New": "#FFD700",
        //"Qualified": "#32CD32",
        //"Needs Analysis": "#1E90FF",
        "Proposal/Price Quote": "#FFA500",
        //"Negotiation": "#FF6347",
        //"Verbal Commitment": "#FFDAB9",
        "Closed – Won": "#008000",
        "Closed – Lost": "#FF0000",
        //"On Hold": "#D3D3D3",
        //"Not Qualified": "#8B0000",
        //"Deferred": "#C71585",
        "Transferred": "#20B2AA",
        "Reserve": "#4B0082",
        "Make Contract": "#32CD32",
        "Returning Customer": "#1E90FF",
    };
    const opportunityStatusTranslate = {
        "New": "ลูกค้าใหม่",
        //"Qualified": "ผ่านการรับรอง",
        //"Needs Analysis": "ต้องการการวิเคราะห์",
        "Proposal/Price Quote": "เสนอราคา",
        //"Negotiation": "เจรจาต่อรอง",
        //"Verbal Commitment": "ตกลงซื้อ (ยังไม่ทำเอกสาร)",
        "Closed – Won": "ปิดการขาย - สำเร็จ",
        "Closed – Lost": "ปิดการขาย - ล้มเหลว",
        //"On Hold": "พักการขาย",
        //"Not Qualified": "ไม่ผ่านการรับรอง",
        //"Deferred": "เลื่อน",
        "Transferred": "โอนแล้ว",
        "Reserve": "จอง",
        "Make Contract": "ทำสัญญา",
        "Returning Customer": "ชมซ้ำ",
    }
    const adminRole = JSON.parse(localStorage.getItem('admin_role'));
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [filterStatus, setFilterStatus] = useState('0');
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('year').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [users, setUsers] = useState([]);
    const [filterUser, setFilterUser] = useState(0);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [filterQuestion, setFilterQuestion] = useState(0);


    useEffect(() => {

        const fetchUsers = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users/get/1`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setUsers(data.data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        const adminRole = localStorage.getItem('admin_role');
        const roles = JSON.parse(adminRole);
        if (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_MANAGER')) {
            fetchUsers();
        }

    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
            //setFilterQuestion(res.data.data[0].questionnaire_id);
        }).catch(err => console.log(err));
    }, []);


    const fetchcontents = useCallback((value = 1, search = '', status = '') => {
        setIsLoading(true)

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/get/${value}`,
            params: {
                search: search,
                status: status,
                start_date: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                user_id: filterUser,
                questionnaire_id: filterQuestion,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.opportunities);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
                window.scrollTo(0, 0);
            }
        });
    }, [setIsLoading, filterDate, filterQuestion, filterUser]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchcontents(1, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            fetchcontents();
            return;
        }
        debouncedFetchContents(value);
    }

    const convertStatusToChip = (status) => {
        return (
            <div className='flex items-center gap-2'>
                <div className='w-3 h-3 rounded-full' style={{ backgroundColor: opportunityStatusColors[status] }}></div>
                <span>{opportunityStatusTranslate[status]}</span>
            </div>
        )
    }

    const handleDelete = () => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/opportunities/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpen(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
                fetchcontents();
            }
        });
    }

    const handleStatusChange = (e) => {
        let value = e.target.value;
        setFilterStatus(value);
        fetchcontents(1, '', value);
    }

    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-end w-full'>
                    <div className='flex gap-4 items-end justify-between'>
                        {/*<h1 className='text-2xl font-bold hidden lg:block'>การขาย</h1>*/}
                        <TextField
                            type='search'
                            className='border border-gray-300 rounded-md p-2 outline-none'
                            placeholder='ค้นหา'
                            onChange={handleDebonceSearch}
                            variant="standard"
                            size='small'
                            sx={{ width: '100%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Select
                            value={filterStatus}
                            onChange={handleStatusChange}
                            size='small'
                            sx={{ width: '100%' }}
                            variant='standard'
                            
                        >
                            <MenuItem value='0' >
                                <div className='flex items-center gap-2'>
                                    <div className='w-3 h-3 rounded-full' style={{ backgroundColor: '#887267' }}></div>
                                    <span>สถานะทั้งหมด</span>
                                </div>
                            </MenuItem>
                            {Object.keys(opportunityStatusColors).map((key) => (
                                <MenuItem value={key} key={key}>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-3 h-3 rounded-full' style={{ backgroundColor: opportunityStatusColors[key] }}></div>
                                        <span>{opportunityStatusTranslate[key]}</span>
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>

                        {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') &&

                            <Select
                                value={filterUser}
                                onChange={(e) => {
                                    setFilterUser(e.target.value);
                                }}
                                size='small'
                                sx={{ width: '100%' }}
                                variant='standard'
                            >
                                <MenuItem value={0} >
                                    <div className='flex items-center gap-2'>
                                        <span>เจ้าของทั้งหมด</span>
                                    </div>
                                </MenuItem>
                                {users.map((user, index) => (
                                    <MenuItem value={user.id} key={index}>
                                        <div className='flex items-center gap-2'>
                                            <span>{user.first_name} {user.last_name}</span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>

                        }

                        <Select
                            value={filterQuestion}
                            onChange={(e) => {
                                setFilterQuestion(e.target.value);
                            }}
                            size='small'
                            sx={{ width: '100%' }}
                            variant='standard'
                        >
                            <MenuItem value={0} >
                                <div className='flex items-center gap-2'>
                                    <span>โครงการทั้งหมด</span>
                                </div>
                            </MenuItem>
                            {questionnaires.map((question, index) => (
                                <MenuItem value={question.questionnaire_id} key={index}>
                                    <div className='flex items-center gap-2'>
                                        <span>{question.q_title}</span>
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>

                        <div className='border-b border-gray-400'>
                            <DateRangePicker
                                placement="bottomEnd"
                                appearance="subtle"
                                value={[filterDate.startDate, filterDate.endDate]}
                                onChange={value => {
                                    setFilterDate({
                                        startDate: value[0],
                                        endDate: value[1],
                                    });
                                }}
                            />
                        </div>


                    </div>
                    <div className='flex gap-4'>

                        <ExportExcel
                            apiurl='admin/opportunities/export'
                            questionnaires={questionnaires}
                        />
                    </div>
                </div>

                <div className='table-row-warp mt-4 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-2/12 font-bold'>ชื่อ</div>
                            <div className='w-2/12 font-bold'>เจ้าของ</div>
                            <div className='w-2/12 font-bold'>วันที่สร้าง</div>
                            <div className='w-2/12 font-bold'>สถานะ</div>
                            <div className='w-2/12 font-bold'>โครงการ</div>
                            <div className='w-1/12 font-bold'>AMS Customer</div>
                            <div className='w-1/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-2/12'>{content.op_title}</div>
                                <div className='w-2/12'>{content.first_name} {content.last_name}</div>
                                <div className='w-2/12'>
                                    {new Date(content.create_at).toLocaleDateString('th-TH', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })} {new Date(content.create_at).toLocaleTimeString('th-TH', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                                <div className='w-2/12'>{convertStatusToChip(content.op_status)}</div>
                                <div className='w-2/12'>{content.q_title}</div>
                                <div className='w-1/12'>{content.ams_customer}</div>
                                <div className='w-1/12 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" onClick={() => {
                                            navigate(`/opportunities/update/${content.opportunity_id}`);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" onClick={() => {
                                            setDeleteId(content.opportunity_id)
                                            setOpen(true);
                                        }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center bg-gray-100'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                    <p>ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            disabled={isLoading}
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                                setDeleteId(null);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            className='btn btn-danger'
                            onClick={handleDelete}
                        >
                            ลบ
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default Opportunities;