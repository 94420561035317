import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from '../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';

const Mktbudget = () => {

    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [filter, setFilter] = useState(0);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [openContenttDialog, setOpenContentDialog] = useState(false);
    const [singleData, setSingleData] = useState({
        id: null,
        amount: 0.00,
        start_date: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        end_date: new Date(dayjs().format('YYYY-MM-DD')),
        questionnaire_id: 0,
        q_title: '',
    });

    const fetchcontents = useCallback((value = 1, question = null) => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/get/${value}`,
            params: {
                questionnaire_id: question
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
        }).catch(err => console.log(err));
    }, []);

    const clearSingleData = () => {
        setSingleData({
            id: null,
            amount: 0.00,
            start_date: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
            end_date: new Date(dayjs().format('YYYY-MM-DD')),
            questionnaire_id: 0,
            q_title: '',
        });
    }

    const validateData = () => {
        if (singleData.amount === 0) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกจำนวนเงิน'
            });
            return false;
        }
        if (singleData.start_date === null) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกวันที่เริ่มต้น'
            });
            return false;
        }
        if (singleData.end_date === null) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกวันที่สิ้นสุด'
            });
            return false;
        }
        if (singleData.questionnaire_id === 0) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกโครงการ'
            });
            return false;
        }
        return true;
    }

    const handleAddneworUpdate = (addorupdate) => {

        if (!validateData()) {
            return;
        }


        setIsLoading(true);
        let data = {
            amount: singleData.amount,
            start_date: dayjs(singleData.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(singleData.end_date).format('YYYY-MM-DD'),
            questionnaire_id: singleData.questionnaire_id,
        }
        let method = 'post';
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/addnew`;
        if (addorupdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/update/${singleData.id}`;
            method = 'put';
        }
        axios({
            method: method,
            url: url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpenContentDialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'บันทึกข้อมูลสำเร็จ'
                });
                fetchcontents();
                clearSingleData();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });

    }


    const handleDelete = () => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpen(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
                fetchcontents();
                setDeleteId(null);
                clearSingleData();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const addCommatoNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-end w-full'>
                    <div className='flex gap-4'>
                        <h1 className='text-2xl font-bold'>Marketing Budget</h1>
                        <Select
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                                fetchcontents(1, e.target.value);
                            }}
                            size='small'
                            sx={{ width: 200 }}
                            variant='standard'
                        >
                            <MenuItem value={0}>โครงการทั้งหมด</MenuItem>
                            {questionnaires.map((questionnaire, index) => (
                                <MenuItem key={index} value={questionnaire.questionnaire_id}>{questionnaire.q_title}</MenuItem>
                            ))}
                        </Select>

                    </div>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setOpenContentDialog(true);
                            clearSingleData();
                        }}

                    >
                        + เพิ่ม Budget
                    </Button>
                </div>


                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-1/4 font-bold'>วันที่</div>
                            <div className='w-1/4 font-bold'>โครงการ</div>
                            <div className='w-1/4 text-right font-bold'>จำนวนเงิน</div>
                            <div className='w-1/4 text-right font-bold'>จัดการ</div>
                        </div>

                        <div className='table-row-body'>
                            {contentList && contentList.length > 0 ? contentList.map((content, index) => (
                                <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                    <div className='w-1/4'>
                                        {dayjs(content.start_date).format('YYYY/MM/DD')} - {dayjs(content.end_date).format('YYYY/MM/DD')}
                                    </div>
                                    <div className='w-1/4'>{content.q_title}</div>
                                    <div className='w-1/4 text-right'>{addCommatoNumber(content.amount)}</div>
                                    <div className='w-1/4 flex justify-end gap-2'>
                                        <Tooltip title="แก้ไข" placement="top" arrow >
                                            <IconButton aria-label="edit" onClick={() => {
                                                setOpenContentDialog(true);
                                                setSingleData({
                                                    id: content.id,
                                                    amount: content.amount,
                                                    start_date: new Date(content.start_date),
                                                    end_date: new Date(content.end_date),
                                                    questionnaire_id: content.questionnaire_id,
                                                    q_title: content.q_title,

                                                });
                                            }}>
                                                <EditNoteOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="ลบ" placement="top" arrow >
                                            <IconButton aria-label="delete" onClick={() => {
                                                setOpen(true);
                                                setDeleteId(content.id);
                                                setSingleData({
                                                    id: content.id,
                                                    amount: content.amount,
                                                    start_date: new Date(content.start_date),
                                                    end_date: new Date(content.end_date),
                                                    questionnaire_id: content.questionnaire_id,
                                                    q_title: content.q_title,

                                                });
                                            }}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            )) :
                                <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                    <div className='w-full text-center'>ไม่มีข้อมูล</div>
                                </div>
                            }

                        </div>
                    </div>



                </div>
                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center bg-gray-100'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>


            <Dialog
                onClose={() => setOpenContentDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openContenttDialog}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>{singleData.id === null ? 'เพิ่ม' : 'แก้ไข'}Budget</h2>
                    <div className='flex flex-col gap-4'>

                        <div className='mb-2'>
                            <label className='font-medium block'>เลือกโครงการ</label>
                            <Select
                                fullWidth
                                value={singleData.questionnaire_id}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, questionnaire_id: e.target.value });

                                }}
                                size='small'
                                variant='outlined'
                            >
                                <MenuItem value={0} disabled>เลือกโครงการ</MenuItem>
                                {questionnaires.map((questionnaire, index) => (
                                    <MenuItem key={index} value={questionnaire.questionnaire_id}>{questionnaire.q_title}</MenuItem>
                                ))}
                            </Select>
                        </div>


                        <div className='relative mb-2'>
                            <label className='font-medium'>ระยะเวลา</label>
                            <DateRangePicker
                                size="lg"
                                className='w-full'
                                appearance="default"
                                placeholder="เลือกช่วงเวลาของค่าใช้จ่าย"
                                value={[singleData.start_date, singleData.end_date]}
                                onChange={(value) => {
                                    setSingleData({ ...singleData, start_date: value[0], end_date: value[1] });
                                    console.log(value);
                                }}
                            />
                        </div>

                        <div className='relative mb-2'>
                            <label className='font-medium'>จำนวนเงิน</label>
                            <input
                                type='number'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.amount}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, amount: e.target.value });
                                }}
                            />
                        </div>
                    </div>


                    <div className='flex justify-end items-center gap-2 mt-4'>
                        <Button
                            onClick={() => {
                                setOpenContentDialog(false);
                                clearSingleData();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            autoFocus
                            onClick={() => {
                                handleAddneworUpdate(singleData.id === null ? 'add' : 'update');
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                    <p>ข้อมูล Budget ของโครงการ {singleData.q_title} วันที่ {dayjs(singleData.start_date).format('YYYY/MM/DD')} - {dayjs(singleData.end_date).format('YYYY/MM/DD')} จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                                setDeleteId(null);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            className='btn btn-danger'
                            onClick={handleDelete}
                        >
                            ลบ
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>


        </Layout>
    );
}

export default Mktbudget;