import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

export default function MarketingExpensesVsKPI() {

    const [questionnaires, setQuestionnaires] = useState([]);
    const [filterQuestion, setFilterQuestion] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
            setFilterQuestion(res.data.data[0].questionnaire_id);
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/expenses-versus-kpi`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                        questionnaireId: filterQuestion
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        if (questionnaires.length > 0) {
            fetchData();
        }

    }, [filterDate, filterQuestion, questionnaires]);

    const handleQuestionChange = (event) => {
        setFilterQuestion(event.target.value);
    };

    const pieChartData = {
        labels: ['ค่าใช้จ่าย', "ยอดขาย (Closed – Won, Transferred, Reserve)"],
        datasets: [{
            data: reportData ? [reportData.current_year.expenses, reportData.current_year.closed_won] : [],
            backgroundColor: ['#FF6384', '#36A2EB'],
        }]
    };

    const addCommars = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const exportToExcel = () => {
        if (!reportData) return;

        const data = [
            ['ช่วงเวลา', 'ค่าใช้จ่าย', 'ยอดขาย'],
            ['ล่าสุด', reportData.current_year.expenses, reportData.current_year.closed_won],
            ['เดือนก่อน', reportData.r_1_month_ago.expenses, reportData.r_1_month_ago.closed_won],
            ['2 เดือนก่อน', reportData.r_2_months_ago.expenses, reportData.r_2_months_ago.closed_won],
            ['3 เดือนก่อน', reportData.r_3_months_ago.expenses, reportData.r_3_months_ago.closed_won],
            ['6 เดือนก่อน', reportData.r_6_months_ago.expenses, reportData.r_6_months_ago.closed_won],
            ['ปีก่อน', reportData.last_year.expenses, reportData.last_year.closed_won],
        ];

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, `ค่าใช้จ่ายการตลาดเทียบ KPI ${dayjs(filterDate.startDate).format('DD-MM-YYYY')} ถึง ${dayjs(filterDate.endDate).format('DD-MM-YYYY')}.xlsx โครงการ ${questionnaires.find(q => q.questionnaire_id === filterQuestion).q_title}.xlsx`);

    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    {questionnaires.length > 0 &&
                        <Select
                            value={filterQuestion}
                            onChange={handleQuestionChange}
                            size='small'
                            sx={{
                                backgroundColor: '#fff',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e5e5ea',
                                }
                            }}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.questionnaire_id} value={questionnaire.questionnaire_id}>
                                    {questionnaire.q_title}
                                </MenuItem>
                            ))}
                        </Select>
                    }

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                    <Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            <div style={{ width: '500px' }} className='mx-auto'>
                                <Pie data={pieChartData} />
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='border border-gray-200 px-4 py-2 text-left'>ช่วงเวลา</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ค่าใช้จ่าย</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ยอดขาย</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData && (
                                        <>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2 text-left'>ล่าสุด</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.current_year.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.current_year.closed_won)}</td>
                                            </tr>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2 text-left'>เดือนก่อน</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_1_month_ago.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_1_month_ago.closed_won)}</td>
                                            </tr>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2 text-left'>2 เดือนก่อน</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_2_months_ago.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_2_months_ago.closed_won)}</td>
                                            </tr>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2 text-left'>3 เดือนก่อน</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_3_months_ago.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_3_months_ago.closed_won)}</td>
                                            </tr>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2 text-left'>6 เดือนก่อน</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_6_months_ago.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_6_months_ago.closed_won)}</td>
                                            </tr>
                                            <tr>
                                                <td className='border border-gray-200 px-4 py-2'>ปีก่อน</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.last_year.expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.last_year.closed_won)}</td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Layout >
    );
}
