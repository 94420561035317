import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function MediaSourceOp() {

    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/media-source-by-op-status-report`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchData();
    }, [filterDate]);

    const barChartData = {
        labels: reportData?.current_year.map(item => item.text_answer) || [],
        datasets: [
            {
                label: 'ล่าสุด',
                data: reportData?.current_year.map(item => item.duplicate_count) || [],
                backgroundColor: `#FF6384`,
                borderColor: `#FF6384`,
                borderWidth: 1,
            },
            {
                label: 'เดือนก่อน',
                data: reportData?.r_1_month_ago?.map(item => item.duplicate_count) || [],
                backgroundColor: `#36A2EB`,
                borderColor: `#36A2EB`,
                borderWidth: 1
            },
            {
                label: '2 เดือนก่อน',
                data: reportData?.r_2_month_ago?.map(item => item.duplicate_count) || [],
                backgroundColor: `#FFCE56`,
                borderColor: `#FFCE56`,
                borderWidth: 1
            },
            {
                label: '3 เดือนก่อน',
                data: reportData?.r_3_month_ago?.map(item => item.duplicate_count) || [],
                backgroundColor: `#4BC0C0`,
                borderColor: `#4BC0C0`,
                borderWidth: 1
            },
            {
                label: '6 เดือนก่อน',
                data: reportData?.r_6_month_ago?.map(item => item.duplicate_count) || [],
                backgroundColor: `#FF6B6B`,
                borderColor: `#FF6B6B`,
                borderWidth: 1
            },
            {
                label: 'ปีก่อน',
                data: reportData?.last_year.map(item => item.duplicate_count) || [],
                backgroundColor: `#9966FF`,
                borderColor: `#9966FF`,
                borderWidth: 1,
            },
        ],
    };

    const exportToExcel = () => {
        if (!reportData) return;

        const data = reportData?.current_year.map(item => ({
            'สื่อ': item.text_answer,
            'ล่าสุด': item.duplicate_count,
            'เดือนก่อน': reportData?.r_1_month_ago && reportData.r_1_month_ago.find(i => i.text_answer === item.text_answer) ? reportData.r_1_month_ago.find(i => i.text_answer === item.text_answer).duplicate_count : 0,
            '2 เดือนก่อน': reportData?.r_2_month_ago && reportData.r_2_month_ago.find(i => i.text_answer === item.text_answer) ? reportData.r_2_month_ago.find(i => i.text_answer === item.text_answer).duplicate_count : 0,
            '3 เดือนก่อน': reportData?.r_3_month_ago && reportData.r_3_month_ago.find(i => i.text_answer === item.text_answer) ? reportData.r_3_month_ago.find(i => i.text_answer === item.text_answer).duplicate_count : 0,
            '6 เดือนก่อน': reportData?.r_6_month_ago && reportData.r_6_month_ago.find(i => i.text_answer === item.text_answer) ? reportData.r_6_month_ago.find(i => i.text_answer === item.text_answer).duplicate_count : 0,
            'ปีก่อน': reportData?.last_year && reportData.last_year.find(i => i.text_answer === item.text_answer) ? reportData.last_year.find(i => i.text_answer === item.text_answer).duplicate_count : 0,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Media Source');
        XLSX.writeFile(workbook, `Top 3 สื่อที่ลูกค้าจอง ${dayjs(filterDate.startDate).format('DD/MM/YYYY')} - ${dayjs(filterDate.endDate).format('DD/MM/YYYY')}.xlsx`);
    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                    <Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>
                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            <Bar data={barChartData} />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse border border-gray-300'>
                                    <thead>
                                        <tr>
                                            <th className='border border-gray-200 px-4 py-2 text-left'>สื่อ</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>ล่าสุด</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>เดือนก่อน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>2 เดือนก่อน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>3 เดือนก่อน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>6 เดือนก่อน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right'>ปีก่อน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData?.current_year.map((item, index) => (
                                            <tr key={index} className='hover:bg-slate-50'>
                                                <td className='border border-gray-200 px-4 py-2'>{item.text_answer}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{item.duplicate_count}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData?.r_1_month_ago && reportData.r_1_month_ago[index] ? reportData.r_1_month_ago[index].duplicate_count : 0}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData?.r_2_month_ago && reportData.r_2_month_ago[index] ? reportData.r_2_month_ago[index].duplicate_count : 0}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData?.r_3_month_ago && reportData.r_3_month_ago[index] ? reportData.r_3_month_ago[index].duplicate_count : 0}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData?.r_6_month_ago && reportData.r_6_month_ago[index] ? reportData.r_6_month_ago[index].duplicate_count : 0}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData?.last_year && reportData.last_year[index] ? reportData.last_year[index].duplicate_count : 0}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>


                        </CardContent>
                    </Card>
                </div>
            </div>

        </Layout >
    );
}
