import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import TitleIcon from '@mui/icons-material/TitleOutlined';
import InputIcon from '@mui/icons-material/InputOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SliderIcon from '@mui/icons-material/TuneOutlined';
import TextFieldsIcon from '@mui/icons-material/TextFieldsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const FormPreviewItem = React.memo(({ element, idx, moveItem, handleRemove, onSelect, selectedElement, icon, handleMoveUp, handleMoveDown, setElements, elementCounter, setElementCounter, elements, clickCount, setClickCount }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'FORM_ELEMENT',
        hover: (draggedItem, monitor) => {
            if (!ref.current) {
                //console.log("return no ref");
                return;
            }

            if (draggedItem.idx === idx) {
                //console.log("return same idx");
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (draggedItem.idx < idx && hoverClientY < hoverMiddleY) {
                return;
            }
            if (draggedItem.idx > idx && hoverClientY > hoverMiddleY) {
                return;
            }


            moveItem(draggedItem.idx, idx);
            draggedItem.idx = idx;

            //console.log("draggedItem:", draggedItem);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'FORM_ELEMENT',
        item: { type: 'FORM_ELEMENT', id: element.id, idx },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const copyitem = (e, idx) => {
        e.stopPropagation();
        const newElement = { ...element, id: `${Date.now()}-${elementCounter}` };
        setElementCounter(prevCounter => prevCounter + 1);
        const updatedElements = [...elements];
        updatedElements.splice(idx + 1, 0, newElement);
        setElements(updatedElements);
    }

    const handleSelect = () => {
        if (selectedElement?.id === element.id) {
            setClickCount((prevCount) => prevCount + 1);
            return;
        }
        onSelect(element);
    }


    return (
        <div
            ref={ref}
            className={`cursor-pointer px-4 py-2 border rounded-lg shadow ${element.type === 'page' ? 'bg-col2' : 'bg-white'} flex justify-between items-center gap-3 ${selectedElement?.id === element.id ? ' border-col1' : 'border-transparent'} ${element.columns ? 'col-span-' + element.columns : 'col-span-4'}`}
            style={{
                opacity: isDragging ? 0.9 : 1,
                marginTop: isDragging ? '1.5rem' : 0,
                marginBottom: isDragging ? '1.5rem' : 0,
                backgroundColor: isDragging ? '#f7fafc' : 'white',
            }}
            onClick={(e) => handleSelect(e, idx)}
            onDragEnterCapture={(e) => {
                //setClickCount((prevCount) => prevCount + 1);
                //console.log("drag capture");
            }}
        >
            <button className='cursor-grab text-slate-600'>
                {
                    element.type === 'page' && <NoteAddOutlinedIcon />
                }
                {
                    element.type === 'section' && <ViewAgendaOutlinedIcon />
                }
                {
                    element.type === 'sectionEnd' && <ViewAgendaOutlinedIcon />
                }
                {
                    element.type === 'title' && <TitleIcon />
                }
                {
                    element.type === 'input' && <InputIcon />
                }
                {
                    element.type === 'number' && <LooksOneOutlinedIcon />
                }
                {
                    element.type === 'phone' && <LocalPhoneOutlinedIcon />
                }
                {
                    element.type === 'email' && <EmailOutlinedIcon />
                }
                {
                    element.type === 'textarea' && <TextFieldsIcon />
                }
                {
                    element.type === 'checkbox' && <CheckBoxOutlineBlankIcon />
                }
                {
                    element.type === 'radio' && <RadioButtonCheckedIcon />
                }
                {
                    element.type === 'select' && <ListOutlinedIcon />
                }
                {
                    element.type === 'slider' && <SliderIcon />
                }
                {
                    element.type === 'address' && <HomeOutlinedIcon />
                }
                {
                    element.type === 'image' && <ImageOutlinedIcon />
                }
                {
                    element.type === 'firstName' && <Person2OutlinedIcon />
                }
                {
                    element.type === 'lastName' && <Person2OutlinedIcon />
                }
                {
                    element.type === 'submit' && <SendOutlinedIcon />
                }
                {
                    element.type === 'imageCheckbox' && <SwitchAccountOutlinedIcon />
                }
            </button>

            <div>
                <div className='truncate text-sm text-slate-600'>{element.label ? element.label : element.name}</div>
            </div>
            <div className='flex'>

                <button onClick={(e) => copyitem(e, idx)}>
                    <ContentCopyOutlinedIcon />
                </button>

                <button onClick={(e) => handleMoveUp(e, idx)}>
                    <KeyboardArrowUpOutlinedIcon />
                </button>

                <button onClick={(e) => handleMoveDown(e, idx)}>
                    <KeyboardArrowDownOutlinedIcon />
                </button>


                <IconButton onClick={() => handleRemove(idx)}>
                    <ClearOutlinedIcon />
                </IconButton>
            </div>
        </div>
    );
});

export default FormPreviewItem;