import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from '../../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DebounceSearch from '../../components/DebounceSearch';
import DeleteDialog from '../../components/Dialog/DeleteDialog';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import * as XLSX from 'xlsx';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';

const Expenses = () => {

    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [sumBudget, setSumBudget] = useState(0);
    const [sumAllExpenses, setSumAllExpenses] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState(0);
    const [allPage, setAllPage] = useState(0);
    const [options, setOptions] = useState([]);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openContenttDialog, setOpenContentDialog] = useState(false);
    const [budgets, setBudgets] = useState(0);
    const [sumExpenses, setSumExpenses] = useState(0);
    const [showOverBudget, setShowOverBudget] = useState({
        show: false,
        over: 0,
        value: 0,
    });
    const allowfetchBudgets = useRef(false);
    const [singleData, setSingleData] = useState({
        expense_id: null,
        questionnaire_id: 0,
        category_id: 0,
        option_id: 0,
        amount: 0.00,
        description: '',
        start_date: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        end_date: new Date(dayjs().format('YYYY-MM-DD')),
    });
    const clearSingleData = () => {
        setSingleData({
            expense_id: null,
            questionnaire_id: 0,
            category_id: 0,
            option_id: 0,
            amount: 0.00,
            description: '',
            start_date: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
            end_date: new Date(dayjs().format('YYYY-MM-DD')),
        });
    }

    const fetchcontents = useCallback((value = 1, search = '') => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/get/${value}`,
            params: {
                start: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                filter: filter,
                search: search,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.expenses);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading, filterDate.startDate, filterDate.endDate, filter]);


    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);


    useEffect(() => {

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setQuestionnaires(response.data.data);
                //setFilter(response.data.data[0].questionnaire_id);
                //fetchcontents(1, '', response.data.data[0].questionnaire_id);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expense-categories/get/1`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setCategories(response.data.categories);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });

    }, [setIsLoading]);


    const fetchSunAllExpenses = useCallback(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/filter-sum-all`,
            params: {
                start: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                questionnaire_id: filter,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setSumAllExpenses(response.data.total);
                setSumExpenses(response.data.total);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [filterDate.startDate, filterDate.endDate, filter, setIsLoading]);

    useEffect(() => {
        fetchSunAllExpenses();
    }, [fetchSunAllExpenses]);


    useEffect(() => {

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/get/1`,
            params: {
                start_date: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                questionnaire_id: filter,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                const sumbudget = response.data.data.reduce((a, b) => a + Number(b.amount), 0);
                setSumBudget(sumbudget);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });


    }, [filterDate.startDate, filterDate.endDate, filter, setIsLoading]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleDelete = () => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpen(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
                fetchcontents();
                fetchSunAllExpenses()
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const validateData = () => {
        for (const [key, value] of Object.entries(singleData)) {
            //if (key !== 'option_id') {
            if (value === '' || value === 0) {
                console.log(key, value);
                return false;
            }
            //}
        }
        return true;
    }

    const handleAddneworUpdate = (addorupdate) => {

        if (!validateData()) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกข้อมูลให้ครบถ้วน'
            });
            return;
        }

        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/addnew`;
        if (addorupdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/update/${singleData.expense_id}`;
        }
        setIsLoading(true)
        axios({
            method: addorupdate === 'add' ? 'post' : 'put',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                expense_id: singleData.expense_id,
                questionnaire_id: parseInt(singleData.questionnaire_id),
                category_id: parseInt(singleData.category_id),
                option_id: parseInt(singleData.option_id),
                amount: parseFloat(singleData.amount),
                description: singleData.description,
                start_date: dayjs(singleData.start_date).format('YYYY-MM-DD'),
                end_date: dayjs(singleData.end_date).format('YYYY-MM-DD'),
            }
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpenContentDialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: addorupdate === 'add' ? 'เพิ่มข้อมูลสำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'
                });
                fetchcontents();
                fetchSunAllExpenses();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const fetchOptions = (questionnaire_id) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get-options/${questionnaire_id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setOptions(response.data.data);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const fetchSingleData = (id) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/single/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {

                setSingleData({
                    expense_id: response.data.expense.expense_id,
                    questionnaire_id: response.data.expense.questionnaire_id,
                    category_id: response.data.expense.category_id,
                    option_id: response.data.expense.option_id,
                    amount: response.data.expense.amount,
                    description: response.data.expense.description,
                    start_date: new Date(dayjs(response.data.expense.start_date).format('YYYY-MM-DD')),
                    end_date: new Date(dayjs(response.data.expense.end_date).format('YYYY-MM-DD')),
                });

                setOptions(response.data.options);

                setOpenContentDialog(true);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const exportToExcel = () => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/export`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            params: {
                start: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                end: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                const ws = XLSX.utils.json_to_sheet(response.data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "ค่าใช้จ่าย");
                XLSX.writeFile(wb, `ค่าใช้จ่าย_${dayjs(filterDate.startDate).format('DD-MM-YYYY')}_${dayjs(filterDate.endDate).format('DD-MM-YYYY')}.xlsx`);
                setShowFilter(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'Export to Excel สำเร็จ'
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {
        if (singleData.questionnaire_id !== 0 && singleData.start_date !== null && singleData.end_date !== null && allowfetchBudgets.current) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/admin/mkt-budget/get/1`,
                params: {
                    start_date: dayjs(singleData.start_date).format('YYYY-MM-DD'),
                    end_date: dayjs(singleData.end_date).format('YYYY-MM-DD'),
                    questionnaire_id: singleData.questionnaire_id,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                if (response.status === 200) {
                    const sumbudget = response.data.data.reduce((a, b) => a + Number(b.amount), 0);
                    setBudgets(sumbudget);
                    console.log('sumbudget', sumbudget);
                    allowfetchBudgets.current = false;
                }
            }).catch(function (error) {
                if (error.response.data.error) {
                    setAlert({
                        open: true,
                        type: 'error',
                        msg: error.response.data.error
                    });
                }
            });

            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/admin/expenses/filter-sum`,
                params: {
                    start: dayjs(singleData.start_date).format('YYYY-MM-DD'),
                    end: dayjs(singleData.end_date).format('YYYY-MM-DD'),
                    questionnaire_id: singleData.questionnaire_id,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                if (response.status === 200) {
                    setSumExpenses(response.data.total);
                }
            }).catch(function (error) {
                if (error.response.data.error) {
                    setAlert({
                        open: true,
                        type: 'error',
                        msg: error.response.data.error
                    });
                }
            });
        }
    }, [singleData, setBudgets]);

    const addCommatoNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Layout mainClass='bg-gray-100'>

            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-end w-full'>
                    <div className='flex gap-4 items-end'>
                        <h1 className='text-2xl font-bold hidden lg:block'>ค่าใช้จ่าย</h1>
                        <DebounceSearch
                            fetchcontents={fetchcontents}
                        />
                        <Select
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                                //fetchcontents(1, '', e.target.value);
                            }}
                            size='small'
                            sx={{ width: 200 }}
                            variant='standard'
                        >
                            <MenuItem value={0}>โครงการทั้งหมด</MenuItem>
                            {questionnaires.map((questionnaire, index) => (
                                <MenuItem key={index} value={questionnaire.questionnaire_id}>{questionnaire.q_title}</MenuItem>
                            ))}
                        </Select>

                        {/* date range picker */}
                        <div className='border-b border-gray-400'>
                            <DateRangePicker
                                placement="bottomEnd"
                                appearance="subtle"
                                value={[filterDate.startDate, filterDate.endDate]}
                                onChange={value => {
                                    setFilterDate({
                                        startDate: value[0],
                                        endDate: value[1],
                                    });
                                    //fetchcontents(1, '', filter);
                                }}
                            />
                        </div>

                    </div>
                    <div className='flex gap-4 items-end'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setOpenContentDialog(true);
                                clearSingleData();
                            }}
                        >
                            + เพิ่มค่าใช้จ่าย
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setShowFilter(!showFilter);
                            }}
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6'>
                    <div className='bg-white rounded-lg p-4 flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <h2 className='text-lg mb-1'>งบประมาณ</h2>
                                <div className='text-2xl font-bold'>{addCommatoNumber(sumBudget)}</div>
                            </div>
                            <div className='text-col1'>
                                <CurrencyBitcoinOutlinedIcon
                                    sx={{ fontSize: 60 }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='bg-white rounded-lg p-4 flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <h2 className='text-lg mb-1'>ค่าใช้จ่าย</h2>
                                <div className='text-2xl font-bold'>{addCommatoNumber(sumAllExpenses)}</div>
                            </div>
                            <div className='text-col1'>
                                <CurrencyBitcoinOutlinedIcon
                                    sx={{ fontSize: 60 }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='bg-white rounded-lg p-4 flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <h2 className='text-lg mb-1'>คงเหลือ</h2>
                                <div className='text-2xl font-bold'>{
                                    sumBudget - sumAllExpenses < 0 ?
                                        <span className='text-red-500'>{addCommatoNumber(sumBudget - sumAllExpenses)}</span>
                                        :
                                        <span className='text-green-500'>{addCommatoNumber(sumBudget - sumAllExpenses)}</span>
                                }</div>
                            </div>
                            <div className='text-col1'>
                                <CurrencyBitcoinOutlinedIcon
                                    sx={{ fontSize: 60 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='table-row-warp mt-2 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-4/12 font-bold'>ชื่อ</div>
                            <div className='w-3/12 font-bold'>โครงการ</div>
                            <div className='w-3/12 font-bold'>วันที่</div>
                            <div className='w-1/12 font-bold'>จำนวน</div>
                            <div className='w-1/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-4/12'>{content.description}</div>
                                <div className='w-3/12'>{content.q_title}</div>
                                <div className='w-3/12'>{dayjs(content.start_date).format('DD/MM/YYYY')} - {dayjs(content.end_date).format('DD/MM/YYYY')}</div>
                                <div className='w-1/12'>{content.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className='w-1/12 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" onClick={() => {
                                            fetchSingleData(content.expense_id)
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" onClick={() => {
                                            setDeleteId(content.expense_id)
                                            setOpen(true);
                                        }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <DeleteDialog
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
                dialogTitle='ยินยันการลบข้อมูล'
                dialogDescription='ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้'
                setDeleteId={setDeleteId}
                isLoading={isLoading}
            />

            <Dialog
                onClose={() => setOpenContentDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openContenttDialog}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>{singleData.expense_id === null ? 'เพิ่ม' : 'แก้ไข'}ค่าใช้จ่าย</h2>
                    <div className='flex flex-col gap-4'>

                        <div className='relative mb-2'>
                            <label className='font-medium'>ระยะเวลา</label>
                            <DateRangePicker
                                size="lg"
                                className='w-full'
                                appearance="default"
                                placeholder="เลือกช่วงเวลาของค่าใช้จ่าย"
                                value={[singleData.start_date, singleData.end_date]}
                                onChange={(value) => {
                                    setSingleData({ ...singleData, start_date: value[0], end_date: value[1] });
                                    allowfetchBudgets.current = true;
                                    //console.log(value);
                                }}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>เลือกโครงการ</label>
                            <Select
                                fullWidth
                                value={singleData.questionnaire_id}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, questionnaire_id: e.target.value });
                                    if (e.target.value === 1) {
                                        setSingleData({
                                            ...singleData,
                                            questionnaire_id: 1,
                                            option_id: 1,
                                        });
                                    } else {
                                        setSingleData({ ...singleData, questionnaire_id: e.target.value });
                                        fetchOptions(e.target.value);
                                    }
                                    allowfetchBudgets.current = true;
                                }}
                                size='small'
                                variant='outlined'
                            >
                                <MenuItem value={0} disabled>เลือกโครงการ</MenuItem>
                                <MenuItem value={1}>รวมทุกโครงการ</MenuItem>
                                {questionnaires.map((questionnaire, index) => (
                                    <MenuItem key={index} value={questionnaire.questionnaire_id}>{questionnaire.q_title}</MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>รายละเอียดค่าใช้จ่าย</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.description}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, description: e.target.value });
                                }}
                            />
                        </div>

                        <div className='mb-2'>
                            <label className='font-medium block'>หมวดหมู่ค่าใช้จ่าย</label>
                            <Select
                                fullWidth
                                value={singleData.category_id}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, category_id: e.target.value });
                                }}
                                size='small'
                                variant='outlined'
                            >
                                <MenuItem value={0} disabled>เลือกหมวดหมู่ค่าใช้จ่าย</MenuItem>
                                {categories.map((category, index) => (
                                    <MenuItem key={index} value={category.category_id}>{category.category_name}</MenuItem>
                                ))}
                            </Select>
                        </div>



                        <div className='mb-2'>
                            <label className='font-medium block'>เลือกสื่อ</label>
                            <Select
                                fullWidth
                                value={singleData.option_id}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, option_id: e.target.value });
                                }}
                                size='small'
                                variant='outlined'
                            >
                                <MenuItem value={0} disabled>{options.length === 0 ? 'กรุณาเลือกโครงการ' : 'เลือกสื่อ'}</MenuItem>
                                {/*
                                    singleData.questionnaire_id === 1 &&
                                    <MenuItem value={1}>สื่ออื่นๆ</MenuItem>
                            */}
                                <MenuItem value={1}>สื่ออื่นๆ</MenuItem>
                                {options.map((option, index) => (
                                    <MenuItem key={index} value={option.option_id}>{option.option_label}</MenuItem>
                                ))}
                            </Select>
                        </div>



                        <div className='relative mb-2'>
                            <label className='font-medium'>จำนวนเงิน</label>
                            <input
                                type='number'
                                className='border border-gray-300 rounded p-2 w-full appearance-none focus:outline-none focus:border-col1'
                                value={singleData.amount}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, amount: e.target.value });
                                    if (e.target.value > (budgets - sumExpenses)) {
                                        setShowOverBudget({ show: true, over: e.target.value - (budgets - sumExpenses), value: e.target.value });
                                    } else {
                                        setShowOverBudget({ show: false, over: 0, value: 0 });
                                    }
                                }}
                            />
                            {showOverBudget.show ?
                                singleData.questionnaire_id !== 1 ?
                                    <div className='absolute top-full left-0 text-red-500 p-2 rounded mt-1'>
                                        ค่าใช้จ่ายเกินงบประมาณ -{addCommatoNumber(showOverBudget.over)} บาท
                                    </div>
                                    :
                                    (sumBudget - sumAllExpenses) - showOverBudget.value < 0 ?
                                        <div className='absolute top-full left-0 text-red-500 p-2 rounded mt-1'>
                                            ค่าใช้จ่ายเกินงบประมาณ {addCommatoNumber((sumBudget - sumAllExpenses) - showOverBudget.value)} บาท
                                        </div>
                                        : ''
                                :
                                ''
                            }
                        </div>
                    </div>


                    <div className='flex justify-end items-center gap-2 mt-4'>
                        <Button
                            onClick={() => {
                                setOpenContentDialog(false);
                                clearSingleData();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            autoFocus
                            onClick={() => {
                                handleAddneworUpdate(singleData.expense_id === null ? 'add' : 'update');
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                onClose={() => setShowFilter(false)}
                aria-labelledby="simple-dialog-title"
                open={showFilter}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>Export to Excel</h2>
                    <div className='flex flex-col gap-4'>

                        <div className='mb-2'>
                            <label className='font-medium block'>วันที่</label>
                            <DateRangePicker
                                size="lg"
                                className='w-full'
                                appearance="default"
                                placeholder="เลือกช่วงเวลาของค่าใช้จ่าย"
                                value={[filterDate.startDate, filterDate.endDate]}
                                onChange={(value) => {
                                    setFilterDate({ ...filterDate, startDate: value[0], endDate: value[1] });
                                    //console.log(value);
                                }}
                            />
                        </div>

                        <div className='flex justify-end items-center gap-2 mt-4'>
                            <Button
                                disabled={isLoading}
                                autoFocus
                                onClick={() => {
                                    exportToExcel();
                                }}
                            >
                                ส่งออกข้อมูล
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowFilter(false);
                                }}
                            >
                                ยกเลิก
                            </Button>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>

        </Layout>
    )
}

export default Expenses;