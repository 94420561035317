import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingProvider } from "./contexts/LoadingContext"
import { Toaster } from 'react-hot-toast';
import { NotificationProvider } from './contexts/NotificationContext';
import PrivateRoute from "./hooks/useAuth";
import { NavigationProvider } from "./contexts/NavigationContext";

import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import Notfound from "./pages/notfound";
import Dashboard from "./pages/dashboard";
import Submissions from "./pages/submissions";
import Opportunities from "./pages/Opportunities/index";
import Questionnaires from "./pages/Questionnaires/index";
import AddQuestionnaires from "./pages/Questionnaires/addnew";
import UpdateQuestionnaires from "./pages/Questionnaires/update";
import UpdateOpportunities from "./pages/Opportunities/update";
import Users from "./pages/users";
import Customers from "./pages/Customers/index";
import QuestionnairesQrcode from "./pages/Questionnaires/qrcode";
import Expenses from "./pages/Expenses/expenses";
import ExpensesCategories from "./pages/Expenses/categories";
import SalesTargets from "./pages/SalesTargets";
import Activities from "./pages/Activities";
import ActivityCalendar from "./pages/ActivityCalendar";

import Reports from "./pages/Reports/index";
import CustomerVisitsFromSurvey from "./pages/Reports/CustomerVisitsFromSurvey";
import NumberOfCustomerVisits from "./pages/Reports/NumberOfCustomerVisits";
import MarketingExpensesVsKPI from "./pages/Reports/MarketingExpensesVsKPI";
import MarketingExpensesByCategory from "./pages/Reports/MarketingExpensesByCategory";
import MarketingExpensesByMedia from "./pages/Reports/MarketingExpensesByMedia";
import MarketingExpensesByMediaForBookings from "./pages/Reports/MarketingExpensesByMediaForBookings";
import MarketingExpensesByMediaForTransfers from "./pages/Reports/MarketingExpensesByMediaForTransfers";
import OnlineVsOfflineExpensesReport from "./pages/Reports/OnlineVsOfflineExpensesReport";
import SalesVsTargetReport from "./pages/Reports/SalesVsTargetReport";
import TransferVsTargetReport from "./pages/Reports/TransferVsTargetReport";
import CancellationVsTargetReport from "./pages/Reports/CancellationVsTargetReport";
import SummaryReport from "./pages/Reports/SummaryReport";
import MediaSource from "./pages/Reports/MediaSource";
import MediaSourceOp from "./pages/Reports/MediaSourceOp";
import Mktbudget from "./pages/Mktbudget";
import BudgetVsExpenseReport from "./pages/Reports/BudgetVsExpenseReport"

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai Variable"].join(","),
  },
  palette: {
    primary: {
      light: "#470e0e",
      main: "#470e0e",
      dark: "#470e0e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <NotificationProvider>

          <Router>
            <NavigationProvider>
              <Routes>
                <Route index element={<PrivateRoute> <Dashboard /> </PrivateRoute>} />
                <Route path="/auth" element={<Login />} />
                <Route path="/logout" element={<Logout />} />


                <Route path="/submissions" element={<Submissions />} />
                <Route path="/questionnaires" element={<PrivateRoute><Questionnaires /></PrivateRoute>} />
                <Route path="/questionnaires/addnew" element={<PrivateRoute><AddQuestionnaires /></PrivateRoute>} />
                <Route path="/questionnaires/update/:id" element={<PrivateRoute><UpdateQuestionnaires /></PrivateRoute>} />
                <Route path="/questionnaires/qrcode" element={<PrivateRoute><QuestionnairesQrcode /></PrivateRoute>} />
                <Route path="/customers" element={<PrivateRoute> <Customers /> </PrivateRoute>} />
                <Route path="/opportunities" element={<PrivateRoute> <Opportunities /> </PrivateRoute>} />
                <Route path="/opportunities/update/:id" element={<PrivateRoute> <UpdateOpportunities /> </PrivateRoute>} />
                <Route path="/expenses" element={<PrivateRoute> <Expenses /> </PrivateRoute>} />
                <Route path="/expenses-categories" element={<PrivateRoute> <ExpensesCategories /> </PrivateRoute>} />
                <Route path="/sales-targets" element={<PrivateRoute> <SalesTargets /> </PrivateRoute>} />
                <Route path="/users" element={<PrivateRoute> <Users /> </PrivateRoute>} />
                <Route path="/activities" element={<PrivateRoute> <Activities /> </PrivateRoute>} />
                <Route path="/activity-calendar" element={<PrivateRoute> <ActivityCalendar /> </PrivateRoute>} />

                <Route path="/mktbudget" element={<PrivateRoute> <Mktbudget /> </PrivateRoute>} />


                <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />

                <Route
                  path="/reports/customer-visits-from-survey"
                  element={<PrivateRoute><CustomerVisitsFromSurvey /></PrivateRoute>}
                />
                <Route
                  path="/reports/number-of-customer-visits"
                  element={<PrivateRoute><NumberOfCustomerVisits /></PrivateRoute>}
                />
                <Route
                  path="/reports/marketing-expenses-vs-kpi"
                  element={<PrivateRoute><MarketingExpensesVsKPI /></PrivateRoute>}
                />
                <Route
                  path="/reports/marketing-expenses-by-category"
                  element={<PrivateRoute><MarketingExpensesByCategory /></PrivateRoute>}
                />
                <Route
                  path="/reports/marketing-expenses-by-media"
                  element={<PrivateRoute><MarketingExpensesByMedia /></PrivateRoute>}
                />
                <Route
                  path="/reports/marketing-expenses-by-media-for-bookings"
                  element={<PrivateRoute><MarketingExpensesByMediaForBookings /></PrivateRoute>}
                />
                <Route
                  path="/reports/marketing-expenses-by-media-for-transfers"
                  element={<PrivateRoute><MarketingExpensesByMediaForTransfers /></PrivateRoute>}
                />
                <Route
                  path="/reports/online-vs-offline-expenses-report"
                  element={<PrivateRoute><OnlineVsOfflineExpensesReport /></PrivateRoute>}
                />
                <Route
                  path="/reports/sales-vs-target-report"
                  element={<PrivateRoute><SalesVsTargetReport /></PrivateRoute>}
                />
                <Route
                  path="/reports/transfer-vs-target-report"
                  element={<PrivateRoute><TransferVsTargetReport /></PrivateRoute>}
                />
                <Route
                  path="/reports/cancellation-vs-target-report"
                  element={<PrivateRoute><CancellationVsTargetReport /></PrivateRoute>}
                />
                <Route
                  path="/reports/summary-report"
                  element={<PrivateRoute><SummaryReport /></PrivateRoute>}
                />
                <Route
                  path="/reports/media-source-report"
                  element={<PrivateRoute><MediaSource /></PrivateRoute>}
                />
                <Route
                  path="/reports/media-source-report-op"
                  element={<PrivateRoute><MediaSourceOp /></PrivateRoute>}
                />
                <Route
                  path="reports/budget-expense-report"
                  element={<PrivateRoute><BudgetVsExpenseReport /></PrivateRoute>}
                />



                <Route path="/404" element={<Notfound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
            </NavigationProvider>
          </Router>
          <Toaster
            position="bottom-left"
            toastOptions={{
              duration: 30000,
            }}
          />

        </NotificationProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}
export default App;