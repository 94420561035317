import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function SummaryReport() {

    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/summary-report`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchData();
    }, [filterDate]);

    const barChartData = {
        labels: ['View', 'Reserve', 'Transfer', 'Cancel'],
        datasets: [
            {
                label: 'ล่าสุด',
                data: [
                    reportData?.current_year?.view?.count || 0,
                    reportData?.current_year?.reserve?.count || 0,
                    reportData?.current_year?.transfer?.count || 0,
                    reportData?.current_year?.cancel?.count || 0,
                ],
                backgroundColor: `#FF6384`,
                borderColor: `#FF6384`,
                borderWidth: 1,
            },
            {
                label: 'เดือนก่อน',
                data: [
                    reportData?.r_1_month_ago?.view?.count || 0,
                    reportData?.r_1_month_ago?.reserve?.count || 0,
                    reportData?.r_1_month_ago?.transfer?.count || 0,
                    reportData?.r_1_month_ago?.cancel?.count || 0,
                ],
                backgroundColor: `#36A2EB`,
                borderColor: `#36A2EB`,
                borderWidth: 1,
            },
            {
                label: '2 เดือนก่อน',
                data: [
                    reportData?.r_2_months_ago?.view?.count || 0,
                    reportData?.r_2_months_ago?.reserve?.count || 0,
                    reportData?.r_2_months_ago?.transfer?.count || 0,
                    reportData?.r_2_months_ago?.cancel?.count || 0,
                ],
                backgroundColor: `#FFCE56`,
                borderColor: `#FFCE56`,
                borderWidth: 1,
            },
            {
                label: '3 เดือนก่อน',
                data: [
                    reportData?.r_3_months_ago?.view?.count || 0,
                    reportData?.r_3_months_ago?.reserve?.count || 0,
                    reportData?.r_3_months_ago?.transfer?.count || 0,
                    reportData?.r_3_months_ago?.cancel?.count || 0,
                ],
                backgroundColor: `#4BC0C0`,
                borderColor: `#4BC0C0`,
                borderWidth: 1,
            },
            {
                label: '6 เดือนก่อน',
                data: [
                    reportData?.r_6_months_ago?.view?.count || 0,
                    reportData?.r_6_months_ago?.reserve?.count || 0,
                    reportData?.r_6_months_ago?.transfer?.count || 0,
                    reportData?.r_6_months_ago?.cancel?.count || 0,
                ],
                backgroundColor: `#FF6B6B`,
                borderColor: `#FF6B6B`,
                borderWidth: 1,
            },
            {
                label: 'ปีก่อน',
                data: [
                    reportData?.last_year?.view?.count || 0,
                    reportData?.last_year?.reserve?.count || 0,
                    reportData?.last_year?.transfer?.count || 0,
                    reportData?.last_year?.cancel?.count || 0,
                ],
                backgroundColor: `#9966FF`,
                borderColor: `#9966FF`,
                borderWidth: 1,
            },
            /*
            {
                label: 'Close Rate (Current Year)',
                data: [reportData?.current_year?.closeRate || 0],
                backgroundColor: '#6C5F5B',
                borderColor: '#6C5F5B',
                borderWidth: 1,
            },
            {
                label: 'Close Rate (Last Year)',
                data: [reportData?.last_year?.closeRate || 0],
                backgroundColor: '#B2533E',
                borderColor: '#B2533E',
                borderWidth: 1,
            }
            */
        ]
    };

    const barChartTotalValueData = {
        labels: ['View', 'Reserve', 'Transfer', 'Cancel'],
        datasets: [
            {
                label: 'ล่าสุด',
                data: [
                    reportData?.current_year?.view?.total_value || 0,
                    reportData?.current_year?.reserve?.total_value || 0,
                    reportData?.current_year?.transfer?.total_value || 0,
                    reportData?.current_year?.cancel?.total_value || 0,
                ],
                backgroundColor: `#FF6384`,
                borderColor: `#FF6384`,
                borderWidth: 1,
            },
            {
                label: '2 เดือนก่อน',
                data: [
                    reportData?.r_2_months_ago?.view?.total_value || 0,
                    reportData?.r_2_months_ago?.reserve?.total_value || 0,
                    reportData?.r_2_months_ago?.transfer?.total_value || 0,
                    reportData?.r_2_months_ago?.cancel?.total_value || 0,
                ],
                backgroundColor: `#FFCE56`,
                borderColor: `#FFCE56`,
                borderWidth: 1,
            },
            {
                label: '3 เดือนก่อน',
                data: [
                    reportData?.r_3_months_ago?.view?.total_value || 0,
                    reportData?.r_3_months_ago?.reserve?.total_value || 0,
                    reportData?.r_3_months_ago?.transfer?.total_value || 0,
                    reportData?.r_3_months_ago?.cancel?.total_value || 0,
                ],
                backgroundColor: `#4BC0C0`,
                borderColor: `#4BC0C0`,
                borderWidth: 1,
            },
            {
                label: '6 เดือนก่อน',
                data: [
                    reportData?.r_6_months_ago?.view?.total_value || 0,
                    reportData?.r_6_months_ago?.reserve?.total_value || 0,
                    reportData?.r_6_months_ago?.transfer?.total_value || 0,
                    reportData?.r_6_months_ago?.cancel?.total_value || 0,
                ],
                backgroundColor: `#FF6B6B`,
                borderColor: `#FF6B6B`,
                borderWidth: 1,
            },
            {
                label: 'ปีก่อน',
                data: [
                    reportData?.last_year?.view?.total_value || 0,
                    reportData?.last_year?.reserve?.total_value || 0,
                    reportData?.last_year?.transfer?.total_value || 0,
                    reportData?.last_year?.cancel?.total_value || 0,
                ],
                backgroundColor: `#9966FF`,
                borderColor: `#9966FF`,
                borderWidth: 1,
            },
        ]
    };

    const exportToExcel = () => {
        if (!reportData) return;

        let data = Object.keys(reportData?.current_year || {}).map((key) => {
            if (key !== "closeRate") {
                return ({
                    'หมวดหมู่': key.charAt(0).toUpperCase() + key.slice(1),
                    'ล่าสุด (จำนวน)': reportData.current_year?.[key]?.count || 0,
                    'ล่าสุด (ยอดขาย)': reportData.current_year?.[key]?.total_value || 0,
                    'เดือนก่อน (จำนวน)': reportData.r_1_month_ago?.[key]?.count || 0,
                    'เดือนก่อน (ยอดขาย)': reportData.r_1_month_ago?.[key]?.total_value || 0,
                    '2 เดือนก่อน (จำนวน)': reportData.r_2_months_ago?.[key]?.count || 0,
                    '2 เดือนก่อน (ยอดขาย)': reportData.r_2_months_ago?.[key]?.total_value || 0,
                    '3 เดือนก่อน (จำนวน)': reportData.r_3_months_ago?.[key]?.count || 0,
                    '3 เดือนก่อน (ยอดขาย)': reportData.r_3_months_ago?.[key]?.total_value || 0,
                    '6 เดือนก่อน (จำนวน)': reportData.r_6_months_ago?.[key]?.count || 0,
                    '6 เดือนก่อน (ยอดขาย)': reportData.r_6_months_ago?.[key]?.total_value || 0,
                    'ปีก่อน (จำนวน)': reportData.last_year?.[key]?.count || 0,
                    'ปีก่อน (ยอดขาย)': reportData.last_year?.[key]?.total_value || 0,
                });
            }
            return null;
        });

        // Filter out null values
        data = data.filter(item => item !== null);

        // Add Close Rate data to the bottom
        data.push({
            'หมวดหมู่': 'Close Rate',
            'ล่าสุด (จำนวน)': reportData.current_year?.closeRate || 0,
            'เดือนก่อน (จำนวน)': reportData.r_1_month_ago?.closeRate || 0,
            '2 เดือนก่อน (จำนวน)': reportData.r_2_months_ago?.closeRate || 0,
            '3 เดือนก่อน (จำนวน)': reportData.r_3_months_ago?.closeRate || 0,
            '6 เดือนก่อน (จำนวน)': reportData.r_6_months_ago?.closeRate || 0,
            'ปีก่อน (จำนวน)': reportData.last_year?.closeRate || 0,
        });

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `สรุปยอดขาย ${dayjs(filterDate.startDate).format('DD/MM/YYYY')} - ${dayjs(filterDate.endDate).format('DD/MM/YYYY')}.xlsx`);
    }


    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                    <Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            <h3 className='text-xl font-bold text-center'>จำนวน</h3>
                            <Bar data={barChartData} />
                        </CardContent>
                    </Card>

                    <Card className='my-4'>
                        <CardContent>
                            <h3 className='text-xl font-bold text-center'>ยอดรวม</h3>
                            <Bar data={barChartTotalValueData} />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse border border-gray-300'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className='border border-gray-200 px-4 py-2 text-left'>หมวดหมู่</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>ล่าสุด</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>2 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>3 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>6 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>ปีก่อน</th>
                                        </tr>
                                        <tr>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>จำนวน</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดขาย</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(reportData?.current_year || {}).map((key) => {
                                            if (key !== "closeRate") {
                                                return (
                                                    <tr key={key}>
                                                        <td className='border border-gray-200 px-4 py-2'>{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{
                                                            // add comma to number

                                                            reportData.current_year[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                                        }</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.current_year[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_2_months_ago[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_2_months_ago[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_months_ago[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_months_ago[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_6_months_ago[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_6_months_ago[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year[key].count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year[key].total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                    </tr>
                                                );
                                            }
                                            return null;
                                        })}
                                        <tr>
                                            <td className='border border-gray-200 px-4 py-2'>Close Rate</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.current_year?.closeRate?.toFixed(2)}%</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.r_1_month_ago?.closeRate?.toFixed(2)}%</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.r_2_months_ago?.closeRate?.toFixed(2)}%</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.r_3_months_ago?.closeRate?.toFixed(2)}%</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.r_6_months_ago?.closeRate?.toFixed(2)}%</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right' colSpan="2">{reportData?.last_year?.closeRate?.toFixed(2)}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </CardContent>
                    </Card>
                </div>
            </div>

        </Layout >
    );
}
